import React from 'react';
import Page from '../../components/Page';
import "../../components/customScroll.styles.scss";
import { Link, useNavigate } from 'react-router-dom';
import { FaDesktop } from 'react-icons/fa';
import { RxArrowTopRight } from "react-icons/rx";



export default function Builder() {
    const navigate = useNavigate();
    const CardBuilder = ({ titleElem, description, link }: { titleElem: JSX.Element, description: string, link: string }) => {

        const handleIconClick = () => {
            navigate(`${process.env.REACT_APP_MODULES_URL}marketplace`);
        };

        return (
            <div  className='flex gap-6 items-center border-2 border-solid border-zinc-300 rounded-2xl p-8 w-96 bg-zinc-100'>
                <Link to={link} className='flex flex-col gap-4 justify-center'>
                    <div className='flex gap-2' >
                        {titleElem}
                        <RxArrowTopRight className='cursor-pointer' size={24} title="Go to" />
                    </div>
                    <p className='text-zinc-500'>{description}</p>
                </Link>
                <FaDesktop className='cursor-pointer' onClick={handleIconClick} size={32} title="MarketPlace" />
            </div>
        )
    }

    return (
        <Page >
            <div className='h-full min-h-screen'>
                <div>
                    <p className=' text-xl'>Builder</p>
                </div>
                <div className='flex gap-8 pt-10'>
                    <CardBuilder
                        titleElem={
                            <div className='flex gap-2'>
                                <p className='text-xl'>Go to Input</p>
                                <p className='text-xl text-blue-400'>In-site</p>
                            </div>}
                        description='Builder de inputs nos websites'
                        link='/builder/inSite' />
                    <CardBuilder
                        titleElem={
                            <p className='text-xl'>Go to Campanhas</p>
                        }
                        description='Builder de campanha de newsletter'
                        link='/builder/campaign' />
                </div>
            </div>
        </Page>

    );
}

