import React, { useEffect, useState } from 'react';
import Page from '../components/Page';
import Loading from '../components/Loading/loading';
import "../components/customScroll.styles.scss";
import { IoIosClose, IoIosSearch } from 'react-icons/io';
import Modal from '../components/Modal';
import { useUser } from '../contexts/userContext';
import { EmailSender } from '../interfaces/Senders';
import { SendersUpdate } from '../interfaces/SendersUpdate';
import { clearCache, deleteData, fetchData, postData, putData } from '../components/DataHandler';
import Toaster from '../components/Toaster';
import eyeClose from '../content/images/eyeClose.svg';
import eyeOpen from '../content/images/eyeOpen.svg';
import { useCallback } from 'react';
import fomatDate from '../components/DateFormatter';
import { IoHelpCircleSharp } from 'react-icons/io5';

const emailProviders = [
    { id: 'gmail', name: 'Gmail' },
    { id: 'yahoo', name: 'Yahoo' },
    { id: 'outlook', name: 'Outlook' },
    { id: 'hotmail', name: 'Hotmail' },
];
export default function Settings() {
    const { user } = useUser();
    const [loading, setLoading] = useState<boolean>(false);
    const [users, setUsers] = useState<EmailSender[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<EmailSender[]>([]);
    const lang = navigator.language || 'en';
    const [search, setSearch] = useState<string>("");
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [editRowVisible, setEditRowVisible] = useState(-1);
    const [provider, setProvider] = useState<string>("");
    const [password, setpassword] = useState<string>("");
    const [newEmail, setNewEmail] = useState<string>("");
    const [newProvider, setNewProvider] = useState<string>("");
    const [newpassword, setNewpassword] = useState<string>("");
    const [host, setHost] = useState<string>("");
    const [port, setPort] = useState<string>("");
    const [username, setUsername] = useState<string>("");
    const [newHost, setNewHost] = useState<string>("");
    const [newPort, setNewPort] = useState<string>("");
    const [newUsername, setNewUsername] = useState<string>("");
    const [isPasswordVisible, setIsPasswordVisible] = useState<boolean>(false);
    const [userEmail, setUserEmail] = useState<string>("");

    function togglePasswordVisibility() {
        setIsPasswordVisible((prevState) => !prevState);
    }

    const handleSendersGetter = useCallback(async () => {
        setLoading(true);
        const idToken = user ? user!.idToken : null;
        const response = await fetchData("/senders/", idToken);
        if (response.status === 200 || response.status === 201) {
            const senders = response.data;
            setUsers(senders);
        } else {
            Toaster.show('Não foi possível obter senders.', 'error');
            console.error('Create sender failed');
        }
        setLoading(false);
    }, [user]);

    const handleCreateSender = async () => {

        const idToken = user ? user!.idToken : null;

        if (newUsername === '' || newEmail === '' || newProvider === '' || newpassword === '' || newHost === '' || newPort === '') {
            Toaster.show('Preencha todos os campos.', 'error');
            return;
        }

        if (isNaN(Number(newPort))) {
            Toaster.show('O campo "Port" deve ser um número.', 'error');
            return;
        }

        setLoading(true);
        const senderObj = {
            sender_email: newEmail,
            sender_service: newProvider,
            host: newHost,
            port: newPort,
            username: newUsername,
            password: newpassword
        }
        const response = await postData("/senders/", senderObj, idToken);
        if (response.status === 200 || response.status === 201) {
            const newSenders = [...users, { ...senderObj, createdat: response.data.createdat, updatedat: response.data.updatedat }];
            setUsers(newSenders);
            clearCache("/senders/");
            Toaster.show(`Sender ${newUsername} criado com sucesso.`, 'success');
        } else {
            Toaster.show('Não foi possível criar sender. Tente novamente.', 'error');
            console.error('Create sender failed');
        }

        setLoading(false);
        setNewpassword("");
        setNewEmail("");
        setNewProvider("");
        setNewUsername("");
        setNewHost("");
        setNewPort("");

        setShowModal(false);
    }


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };


    const toggleEditRow = (index: number, elem: EmailSender | null) => {
        setEditRowVisible(index);
        if (elem) {
            setUserEmail(elem.sender_email);
            setUsername(elem.username);
            setProvider(elem.sender_service);
            setpassword(elem.password);
            setHost(elem.host);
            setPort(elem.port);
        } else {
            setUserEmail("");
            setUsername("");
            setProvider("");
            setpassword("");
            setHost("");
            setPort("");
        }
    };

    const handleDelete = async (item: EmailSender) => {
        const idToken = user ? user!.idToken : null;
        const response = await deleteData(`/senders/${item.sender_email}`, idToken);
        if (response.status === 200 || response.status === 201) {
            setUsers(prevUsers => {
                const updatedUsers = prevUsers.filter(user => user.sender_email !== item.sender_email);
                return updatedUsers;
            });
            clearCache("/senders/");
            Toaster.show(`Sender ${item.username} apagado com sucesso.`, 'success');
        } else {
            Toaster.show('Não foi possível apagar sender. Tente novamente.', 'error');
            console.error('Delete sender failed');
        }
        setLoading(false)
        toggleEditRow(-1, null);
    }


    const handleUpdate = async (item: EmailSender) => {
        const idToken = user ? user!.idToken : null;
        let sendersUpdate: SendersUpdate = {};
        if (provider !== "") {
            sendersUpdate.sender_service = provider;
        }
        if (password !== "") {
            sendersUpdate.password = password;
        }
        if (host !== "") {
            sendersUpdate.host = host;
        }
        if (port !== "") {
            sendersUpdate.port = port;
        }
        if (username !== "") {
            sendersUpdate.username = username;
        }
        if (userEmail !== "") {
            sendersUpdate.new_email = userEmail;
        }
        const response = await putData(`/senders/${item.sender_email}`, sendersUpdate, idToken);
        if (response.status === 200 || response.status === 201) {
            const updatedUsers = [
                ...users.filter(user => user.sender_email !== item.sender_email),
                response.data
            ];
            setUsers(updatedUsers);
            clearCache("/senders/");
            Toaster.show(`User ${username} updated com sucesso.`, 'success');
        } else {
            Toaster.show('Não foi possível editar sender. Tente novamente.', 'error');
            console.error('Edit sender failed');
        }
        setUserEmail("");
        setUsername("");
        setPort("");
        setHost("");
        setProvider("");
        setpassword("");
        toggleEditRow(-1, null);
        setLoading(false)

    }

    const handleCancel = () => {
        setProvider("");
        setpassword("");
        toggleEditRow(-1, null);
    }

    const toggleCancel = () => {
        setShowModal(false);
        setNewEmail("");
        setNewProvider("");
        setNewpassword("");
    }

    useEffect(() => {
        handleSendersGetter()
    }, [handleSendersGetter]);


    useEffect(() => {
        setFilteredUsers(users);
    }, [users]);

    useEffect(() => {
        if (search === '') {
            setFilteredUsers(users);
            return;
        }
        const filtered = users.filter((user) =>
            user.username.toLowerCase().includes(search.toLowerCase()) || user.sender_email.toLowerCase().includes(search.toLowerCase())
        );

        setFilteredUsers(filtered);

    }, [search, users]);

    return (
        <Page >
            <div className='h-screen'>
                <div className='flex'>
                    <h2 className='text-xl'>E-MAILS</h2>
                </div>
                <div className="py-6">
                    <div className="flex items-center gap-2 justify-between pb-6">
                        <div className='flex gap-2 w-full items-center'>
                            <div className='group relative'>
                                <IoHelpCircleSharp className='text-zinc-500' size={40} />
                                <span className='absolute top-12 scale-0 group-hover:scale-100 transition-all duration-200 ease-in-out bg-zinc-100 w-60 aspect-square rounded-2xl p-2'>
                                    <p className='text-lg text-black'>Ajuda</p>
                                    <p className='text-sm text-zinc-500'>
                                        Poderá pesquisar por nome ou número de equipa.
                                        Equipas inativas são marcadas a vermelho e ativas a verde.
                                        As equipas ativas são as que têm menos membros do que o máximo permitido.
                                        Poderá adicionar uma nova equipa clicando no botão no canto superior direito e posteriormente adicionar novos membros.
                                    </p>
                                </span>
                            </div>
                            <div className="w-1/2">
                                <div className=" bg-gray-200 rounded-full shadow flex px-2 py-1 w-fit">
                                    <input type="text" name="search" className={`${showSearch ? "w-60 px-4" : "w-0"} transition-all duration-300 ease-in-out dark:text-gray-800 outline-none bg-transparent`} onChange={handleSearchChange} value={search} />
                                    <button type={showSearch ? "submit" : "button"}>
                                        <div>
                                            <IoIosSearch onClick={() => setShowSearch(!showSearch)} className='text-zinc-500' size={36} title="Pesquisar" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <button onClick={() => setShowModal(true)} className="bg-dashBlue w-60 text-white py-2 px-4 rounded-full flex items-center justify-center gap-2">
                                <span>+</span>
                                <span>{lang === "pt" ? "Novo E-Mail" : "New E-Mail"}</span>
                            </button>
                        </div>
                    </div>
                    {filteredUsers.length > 0 ?
                        <div className='scroll overflow-auto max-h-[40rem] w-full'>
                            <div className=' w-full'>
                                <table className=' w-full'>
                                    <thead >
                                        <tr className='text-sm text-left text-zinc-500 '>
                                            <th className="px-6 py-3">
                                                {lang === "pt" ? "Nome" : "Name"}
                                            </th>
                                            <th className="px-6 py-3  min-w-[8rem]">
                                                E-Mail
                                            </th>
                                            <th className="px-6 py-3  min-w-[10rem]">
                                                {lang === "pt" ? "Data de Criação" : "Criation Date"}
                                            </th>
                                            <th className="px-6 py-3  min-w-[10rem]">
                                                {lang === "pt" ? "Última Atualização" : "Last Update"}
                                            </th>
                                            <th className="px-6 py-3 ">
                                                {lang === "pt" ? "Ações" : "Actions"}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {filteredUsers.map((elem, index) => (
                                            <React.Fragment key={index}>
                                                <tr key={index} className=' text-zinc-500  border-t-2 border-zinc-200'>
                                                    <td className="px-6 py-10">
                                                        <div className="text-sm font-bold text-black rounded-2xl ">{elem.username}</div>
                                                    </td>
                                                    <td className="px-6 py-10">
                                                        <div className="text-sm font-bold text-black rounded-2xl ">{elem.sender_email}</div>
                                                    </td>
                                                    <td className="px-6 py-10  ">
                                                        <div className="text-sm rounded-2xl ">{fomatDate(elem.createdat)}</div>
                                                    </td>
                                                    <td className="px-6 py-10  ">
                                                        <div className="text-sm rounded-2xl ">{fomatDate(elem.updatedat)}</div>
                                                    </td>

                                                    <td className="px-6 py-10 text-sm font-medium">
                                                        <button
                                                            onClick={() => toggleEditRow(index, elem)}
                                                            className="text-sm font-bold text-indigo-600 hover:text-indigo-900">
                                                            Edit
                                                        </button>
                                                    </td>
                                                </tr>

                                                <tr className={`${editRowVisible !== index && "hidden"}`}>
                                                    <td colSpan={6}>
                                                        <div className='flex flex-wrap gap-6 px-8'>
                                                            <div className='flex flex-col gap-2'>
                                                                <label>UserName</label>
                                                                <input
                                                                    onChange={(e) => setUsername(e.target.value)}
                                                                    className='outline-none border border-solid border-zinc-300 rounded-lg py-1 px-4'
                                                                    id="username"
                                                                    name="username"
                                                                    value={username}
                                                                />
                                                            </div>
                                                            <div className='flex flex-col gap-2'>
                                                                <label>Email</label>
                                                                <input
                                                                    onChange={(e) => setUserEmail(e.target.value)}
                                                                    className='outline-none border border-solid border-zinc-300 rounded-lg py-1 px-4'
                                                                    id="email"
                                                                    name="email"
                                                                    value={userEmail}
                                                                />
                                                            </div>
                                                            <div className='flex flex-col gap-2'>
                                                                <label>Provider</label>
                                                                <select
                                                                    className="w-full outline-none px-4 py-2 text-sm border bg-transparent border-gray-300 border-solid rounded-lg"
                                                                    value={provider}
                                                                    onChange={(e) => setProvider(e.target.value)}
                                                                >
                                                                    <option value="" disabled>Select a provider</option>
                                                                    {emailProviders.map((provider, index) => (
                                                                        <option key={index} value={provider.id}>{provider.name}</option>
                                                                    ))}
                                                                </select>

                                                            </div>
                                                            <div className='flex flex-col gap-2'>
                                                                <label>Password</label>
                                                                <div className="relative">
                                                                    <button
                                                                        tabIndex={-1}
                                                                        type='button'
                                                                        className="absolute right-0 flex items-center px-4 text-gray-600 translate-y-2"
                                                                        onClick={togglePasswordVisibility}
                                                                    >
                                                                        {!isPasswordVisible ? (
                                                                            <img src={eyeClose} alt="Not visible" className="w-5 h-5" />
                                                                        ) : (
                                                                            <img src={eyeOpen} alt="Visible" className="w-5 h-5" />
                                                                        )}
                                                                    </button>
                                                                    <div className='flex flex-col gap-2'>
                                                                        {/* <label className="text-lg font-semibold text-white mil:text-black">Password</label> */}
                                                                        <input
                                                                            type={isPasswordVisible ? "text" : "password"}
                                                                            placeholder='| Password'
                                                                            className='outline-none border border-solid border-zinc-300 rounded-lg py-1 px-4'
                                                                            onChange={(e) => setpassword(e.target.value)}
                                                                            value={password}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <div className='flex flex-col gap-2'>
                                                                <label>Host</label>
                                                                <input
                                                                    onChange={(e) => setHost(e.target.value)}
                                                                    className='outline-none border border-solid border-zinc-300 rounded-lg py-1 px-4'
                                                                    type="text"
                                                                    id="host"
                                                                    name="host"
                                                                    value={host}
                                                                />
                                                            </div>
                                                            <div className='flex flex-col gap-2'>
                                                                <label>Port</label>
                                                                <input
                                                                    onChange={(e) => setPort(e.target.value)}
                                                                    className='outline-none border border-solid border-zinc-300 rounded-lg py-1 px-4'
                                                                    type="text"
                                                                    id="port"
                                                                    name="port"
                                                                    value={port}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='py-8 flex justify-between px-8'>
                                                            <button onClick={() => handleDelete(elem)} className='bg-red-500 text-white py-2 px-4 rounded-lg'>
                                                                Delete
                                                            </button>
                                                            <div className='flex gap-8'>
                                                                <button onClick={handleCancel} className='bg-zinc-200 text-black py-2 px-4 rounded-lg'>
                                                                    Cancel
                                                                </button>
                                                                <button onClick={() => handleUpdate(elem)} className='bg-dashBlue text-white py-2 px-4 rounded-lg'>
                                                                    Save
                                                                </button>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </React.Fragment>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                        : <div className='flex justify-center items-center'>
                            <p className='text-xl'>{lang === "pt" ? (search !== '' ? "Sem resultados para a pesquisa" : "Sem senders, crie um novo sender") : (search !== '' ? "No results for the search" : "No senders, create a new sender")}</p>
                        </div>
                    }
                </div>
            </div>
            <Modal isOpen={showModal} handler={handleCreateSender}>
                <div className='p-8'>
                    <div className='flex justify-between'>
                        <div>
                            <p className='text-xl'>{lang === "pt" ? "Novo E-mail" : "New E-mail"}</p>
                        </div>
                        <IoIosClose onClick={() => setShowModal(false)} className='cursor-pointer' size={40} title={lang === "pt" ? "Fechar" : "Close"} />
                    </div>
                    <div className='py-6'>
                        <div className='flex flex-col gap-6  justify-between px-8'>
                            <div className='flex flex-col gap-2'>
                                <label >Username</label>
                                <input
                                    value={newUsername}
                                    onChange={(e) => setNewUsername(e.target.value)}
                                    className='outline-none border border-solid border-zinc-300 rounded-lg py-1 px-4'
                                    type="text" />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <label >Email</label>
                                <input
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                    className='outline-none border border-solid border-zinc-300 rounded-lg py-1 px-4'
                                    type="text" />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <label>Provider</label>
                                <select
                                    className="w-full outline-none px-4 py-2 text-sm border bg-transparent border-gray-300 border-solid rounded-lg"
                                    value={newProvider}
                                    onChange={(e) => setNewProvider(e.target.value)}
                                >
                                    <option value="" disabled>Select a provider</option>
                                    {emailProviders.map((provider, index) => (
                                        <option key={index} value={provider.id}>{provider.name}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <label >API Password</label>
                                <div className="relative">
                                    <button
                                        tabIndex={-1}
                                        type='button'
                                        className="absolute right-0 flex items-center px-4 text-gray-600 translate-y-2"
                                        onClick={togglePasswordVisibility}
                                    >
                                        {!isPasswordVisible ? (
                                            <img src={eyeClose} alt="Not visible" className="w-5 h-5" />
                                        ) : (
                                            <img src={eyeOpen} alt="Visible" className="w-5 h-5" />
                                        )}
                                    </button>
                                    <div className='flex flex-col gap-2'>
                                        {/* <label className="text-lg font-semibold text-white mil:text-black">Password</label> */}
                                        <input
                                            type={isPasswordVisible ? "text" : "password"}
                                            placeholder='| Password'
                                            className='outline-none border border-solid border-zinc-300 rounded-lg py-1 px-4'
                                            onChange={(e) => setNewpassword(e.target.value)}
                                            value={newpassword}
                                        />
                                    </div>
                                </div>
                            </div>
                            {/*  */}
                            <div className='flex flex-col gap-2'>
                                <label >Host</label>
                                <input
                                    value={newHost}
                                    onChange={(e) => setNewHost(e.target.value)}
                                    className='outline-none border border-solid border-zinc-300 rounded-lg py-1 px-4'
                                    type="text" />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <label >Port</label>
                                <input
                                    value={newPort}
                                    onChange={(e) => setNewPort(e.target.value)}
                                    className='outline-none border border-solid border-zinc-300 rounded-lg py-1 px-4'
                                    type="text" />
                            </div>

                        </div>
                    </div>
                    <div className='flex gap-8 justify-end pt-8'>
                        <button onClick={toggleCancel} className="bg-zinc-200 text-zinc-500 py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Cancelar" : "Cancel"}</span>
                        </button>
                        <button onClick={handleCreateSender} className="bg-dashBlue text-white py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Adicionar" : "Add"}</span>
                        </button>

                    </div>
                </div>
            </Modal>
            <Loading isOpen={loading} />
        </Page>

    );
}

