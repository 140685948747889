
export default function stateBgColor(state: string): { color: string, text: string } {
    switch (state) {
        case 'active':
            return { color: 'bg-lime-400', text: 'Active' };
        case 'inactive':
            return { color: 'bg-red-400', text: 'Inactive' };
        case 'draft':
            return { color: 'bg-purple-400', text: 'Draft' };
        default:
            return { color: '', text: "undefined" }; // Default case if none of the states match
    }
};
