import { toast, ToastOptions } from 'react-hot-toast';

type ToastType = 'success' | 'error' | 'loading' | 'custom';

class CustomToast {
  private static instance: CustomToast;

  private constructor() { }

  public static getInstance(): CustomToast {
    if (!CustomToast.instance) {
      CustomToast.instance = new CustomToast();
    }
    return CustomToast.instance;
  }

  public show(message: string, type: ToastType, options?: ToastOptions): void {
    toast.dismiss(); // Dismiss all existing toasts

    const customMessage = <div style={{ wordBreak: 'break-word' }}>{message}</div>;

    switch (type) {
      case 'success':
        toast.success(customMessage, options);
        break;
      case 'error':
        toast.error(customMessage, options);
        break;
      case 'loading':
        toast.loading(customMessage, options);
        break;
      case 'custom':
        toast(customMessage, options);
        break;
      default:
        toast(customMessage, options);
    }
  }

  public dismiss(): void {
    toast.dismiss();
  }
}

export default CustomToast.getInstance();