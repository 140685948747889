import './loader.scss';
import modules from '../../content/images/modules.png';
const Loading = ({ isOpen }: { isOpen: boolean }) => {

  return (
    <>
      {isOpen && (
        <div className="loader-overlay">
          <div className="loader bg-[var(--primary)] flex items-center">
            <img className='px-3 py-4' src={modules} alt="loading" />
          </div>
        </div>
      )}
    </>
  );
}

export default Loading;