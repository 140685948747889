// src/contexts/CampaignContext.tsx
import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { Campaign } from '../interfaces/Campaign';
import { fetchData, setCache } from '../components/DataHandler';
import { useUser } from './userContext';

interface CampaignContextProps {
    campaigns: Campaign[];
    setCampaigns: React.Dispatch<React.SetStateAction<Campaign[]>>;
    getCampaigns: () => Promise<void>;
}

const CampaignContext = createContext<CampaignContextProps | undefined>(undefined);

export const CampaignProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { user } = useUser();
    const [campaigns, setCampaigns] = useState<Campaign[]>([]);

    const getCampaigns = useCallback(async () => {
        if (user) {
            const idToken = user!.idToken ? user!.idToken : null;
            const response = await fetchData('/campaigns/', idToken);
            if (response.status === 200 || response.status === 201) {
                setCampaigns(response.data);
            }
        }
    }, [user]);

    useEffect(() => {
        getCampaigns();
    }, [getCampaigns]);


    useEffect(() => {
        setCache('/campaigns/', campaigns);
    }, [campaigns]);

    return (
        <CampaignContext.Provider value={{ campaigns, setCampaigns, getCampaigns }}>
            {children}
        </CampaignContext.Provider>
    );
};

export const useCampaigns = (): CampaignContextProps => {
    const context = useContext(CampaignContext);
    if (!context) {
        throw new Error('useCampaigns must be used within a CampaignProvider');
    }
    return context;
};