import React, { useEffect, useState } from 'react';
import { IoHelpCircleSharp } from "react-icons/io5";
import { IoIosClose, IoIosSearch } from "react-icons/io";
import { FaListUl } from "react-icons/fa6";
import "../../components/customScroll.styles.scss";
import Page from '../../components/Page';
import { Campaign } from '../../interfaces/Campaign';
import { MdDashboard } from 'react-icons/md';
import CampaignDashView from './CampaignDashView';
import CampaignListView from './CampaignListView';
import Loading from '../../components/Loading/loading';
import { useUser } from '../../contexts/userContext';
import Wheel from '@uiw/react-color-wheel';
import { hsvaToHex } from '@uiw/color-convert';
import Modal from '../../components/Modal';
import { HSVA } from '../../interfaces/HSVA';
import Toaster from '../../components/Toaster';
import { postData } from '../../components/DataHandler';
import { useCampaigns } from '../../contexts/campaignsContext';

type CampaignState = {
    newCampaignName: string;
    hsva: HSVA;
}

export default function Campaigns() {
    const { user } = useUser();
    const lang = navigator.language || 'en';
    const { campaigns, setCampaigns } = useCampaigns();
    const [inactiveDraftsCampaigns, setinactiveDraftsCampaigns] = useState<Campaign[]>([]);
    const [allFilteredCampaigns, setAllFilteredCampaigns] = useState<Campaign[]>([]);
    const [search, setSearch] = useState<string>("");
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [dashView, setDashView] = useState<boolean>(true);
    const [loading, setLoading] = useState<boolean>(false);
    const [campaignState, setCampaignState] = useState<CampaignState>({
        newCampaignName: "",
        hsva: { h: 214, s: 43, v: 90, a: 1 }
    });

    const updateCampaignState = (key: any, value: any) => {
        setCampaignState(prevState => ({
            ...prevState,
            [key]: value
        }));
    };

    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };



    const toggleCancel = () => {
        updateCampaignState('newCampaignName', '');
        updateCampaignState('hsva', { h: 214, s: 43, v: 90, a: 1 });
        setShowModal(false);
    }


    const handleCampaignCreate = async () => {

        const idToken = user!.idToken ? user!.idToken : null;

        if (campaignState.newCampaignName === '') {
            Toaster.show('Preencha o nome.', 'error');
            return;
        }

        setLoading(true);
        const companyObj = {
            name: campaignState.newCampaignName,
            colorCode: hsvaToHex(campaignState.hsva).substring(1),
        }
        const response = await postData('/campaigns/', companyObj, idToken);
        if (response.status === 200 || response.status === 201) {
            const newCampains = [...campaigns, { name: campaignState.newCampaignName, color: hsvaToHex(campaignState.hsva).substring(1), id: response.data.id, lists: [], createdat: response.data.createdat, updatedat: response.data.updatedat, number: response.data.number, state: response.data.state }];
            setCampaigns(newCampains);
            Toaster.show(`Campanha ${campaignState.newCampaignName} criada com sucesso.`, "success");
        } else {
            Toaster.show('Não foi possível criar a campanha. Tente novamente.', 'error');
            console.error('Create campaign failed');
        }
        setLoading(false);
        updateCampaignState('newCampaignName', '');
        updateCampaignState('hsva', { h: 214, s: 43, v: 90, a: 1 });
        setShowModal(false);
    }

    useEffect(() => {
        if (campaigns.length > 0) {
            const inactiveCampaigns = campaigns.filter(campaign => campaign.state !== "active");
            setinactiveDraftsCampaigns(inactiveCampaigns);
            setAllFilteredCampaigns(campaigns);
        }
    }, [campaigns]);

    useEffect(() => {
        if (search === '') {
            setAllFilteredCampaigns(campaigns);
            return;
        }
        const searchNumber = Number(search);
        const isSearchNumber = !isNaN(searchNumber);
        const filteredAll = campaigns.filter((campaign) =>
            isSearchNumber ? campaign.number === searchNumber :
                campaign.name.toLowerCase().includes(search.toLowerCase())
        );
        setAllFilteredCampaigns(filteredAll);

    }, [search, campaigns]);

    return (
        <Page >

            <div className='h-screen'>
                <div className='flex'>
                    <h2 className='text-xl'>{lang === "pt" ? "Campanhas Ativas" : "Active Campaigns"}</h2>
                </div>
                <div className="py-6">
                    <div className="flex gap-2 pb-6">
                        {campaigns.filter(campaign => campaign.state === "active").map((campaign, index) => (
                            <div key={index} style={{ backgroundColor: `#${campaign.color}` }} className={`w-8 h-8 text-white rounded-full flex items-center justify-center`}>{campaign.number}</div>
                        ))}
                    </div>
                    <div className="flex items-center gap-2 justify-between pb-6">
                    <div className='flex gap-2 w-full items-center'>
                            <div className='group relative'>
                                <IoHelpCircleSharp className='text-zinc-500' size={40} />
                                <span className='absolute top-12 scale-0 group-hover:scale-100 transition-all duration-200 ease-in-out bg-zinc-100 w-60 aspect-square rounded-2xl p-2'>
                                    <p className='text-lg text-black'>Ajuda</p>
                                    <p className='text-sm text-zinc-500'>
                                        Poderá pesquisar por nome ou número de equipa.
                                        Equipas inativas são marcadas a vermelho e ativas a verde.
                                        As equipas ativas são as que têm menos membros do que o máximo permitido.
                                        Poderá adicionar uma nova equipa clicando no botão no canto superior direito e posteriormente adicionar novos membros.
                                    </p>
                                </span>
                            </div>
                            <div className="w-1/2">
                                <div className=" bg-gray-200 rounded-full shadow flex px-2 py-1 w-fit">
                                    <input type="text" name="search" className={`${showSearch ? "w-60 px-4" : "w-0"} transition-all duration-300 ease-in-out dark:text-gray-800 outline-none bg-transparent`} onChange={handleSearchChange} value={search} />
                                    <button type={showSearch ? "submit" : "button"}>
                                        <div>
                                            <IoIosSearch onClick={() => setShowSearch(!showSearch)} className='text-zinc-500' size={36} title="Pesquisar" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <button onClick={() => setShowModal(true)} className="bg-dashBlue w-60 text-white py-2 px-4 rounded-full flex items-center justify-center gap-2">
                                <span>+</span>
                                <span>{lang === "pt" ? "Nova Campanha" : "New Campaign"}</span>
                            </button>
                            <div className='flex gap-4 justify-center'>
                                <MdDashboard onClick={() => setDashView(true)} className={`${dashView ? "text-dashBlue" : "text-zinc-200"} transition-all duration-300 cursor-pointer ease-in-out`} size={34} title="Dashboard View" />
                                <FaListUl onClick={() => setDashView(false)} className={`${!dashView ? "text-dashBlue" : "text-zinc-200"} transition-all duration-300 cursor-pointer ease-in-out`} size={32} title="List View" />
                            </div>
                        </div>
                    </div>
                    {campaigns.length > 0 ?
                        <div>
                            {dashView ?
                                <CampaignDashView filteredcampaigns={allFilteredCampaigns.filter(campaign => campaign.state.toLocaleLowerCase() === "active")} inactiveDraftsCampaigns={inactiveDraftsCampaigns} />
                                :
                                <CampaignListView filteredcampaigns={allFilteredCampaigns} setfilteredcampaigns={setAllFilteredCampaigns} />
                            }
                        </div> :
                        <div className='flex justify-center items-center h-96'>
                            <p className='text-xl text-zinc-500'>{lang === "pt" ? "Não existem campanhas, por favor adicione uma nova" : "There are no campaigns, please add a new one"}</p>

                        </div>
                    }
                </div>
            </div>
            <Loading isOpen={loading} />
            <Modal isOpen={showModal} handler={handleCampaignCreate}>
                <div className='p-8'>
                    <div className='flex justify-between'>
                        <div>
                            <p className='text-xl'>{lang === "pt" ? "Adicionar Campanha" : "Add Campaign"}</p>
                        </div>
                        <IoIosClose onClick={() => setShowModal(false)} className='cursor-pointer' size={40} title={lang === "pt" ? "Fechar" : "Close"} />
                    </div>
                    <div className='py-6'>
                        <div className='p-4 bg-zinc-100 text-zinc-400 rounded-xl flex items-center gap-2'>
                            <IoHelpCircleSharp size={24} />
                            <p className='text-sm'>{lang === "pt" ?
                                "Insira o nome que deseja atribuir à nova campanha que terá de ser único." :
                                "Enter the name you want to assign to the new campaign, which must be unique."}
                            </p>
                        </div>
                        <div className='py-8 flex justify-around items-center'>
                            <div className='flex flex-col gap-8'>
                                <div className='flex flex-col gap-2'>
                                    <label className='text-sm'>{lang === "pt" ? "Nome da Campanha" : "Campaign Name"}</label>
                                    <input
                                        className="w-72 outline-none px-4 py-2 text-sm border border-gray-300 border-solid rounded-full"
                                        type="text"
                                        placeholder='| Name'
                                        onChange={(e) => updateCampaignState('newCampaignName', e.target.value)}
                                        value={campaignState.newCampaignName}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col gap-6'>
                                <Wheel color={campaignState.hsva} onChange={(color) => updateCampaignState('hsva', { ...campaignState.hsva, ...color.hsva })} />
                                <div className='flex justify-center'>
                                    <div className='flex gap-2 items-center'>
                                        <p className='text-xl text-zinc-500'>{lang === "pt" ? "Campanha" : "Campaign"}</p>
                                        <div className='rounded-full h-6 w-6' style={{ background: hsvaToHex(campaignState.hsva) }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-8 justify-end'>
                        <button onClick={toggleCancel} className="bg-zinc-200 text-zinc-500 py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Cancelar" : "Cancel"}</span>
                        </button>
                        <button onClick={handleCampaignCreate} className="bg-dashBlue text-white py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Adicionar" : "Add"}</span>
                        </button>

                    </div>
                </div>
            </Modal>
        </Page>

    );
}

