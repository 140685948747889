import React, { useEffect, useRef, useState, useCallback } from 'react';
import Page from '../../components/Page';
import Loading from '../../components/Loading/loading';
import "../../components/customScroll.styles.scss";
import { LuImport } from "react-icons/lu";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FaDesktop } from 'react-icons/fa';
import { IoMdArrowRoundBack } from "react-icons/io";
import { useUser } from '../../contexts/userContext';
import { CampaignHtmlTemplate } from '../../interfaces/CampaignHtmlTemplate';
import { clearCache, fetchData, postData } from '../../components/DataHandler';
import Toaster from '../../components/Toaster';

export default function Builder() {
    const { user } = useUser();
    const [loading, setLoading] = useState<boolean>(false);
    const location = useLocation();
    const { selected } = location.state || {};
    const [selectedHtml, setSelectedHtml] = useState<string>(selected);
    const [htmls, setHtmls] = useState<CampaignHtmlTemplate[]>([]);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [fileContent, setFileContent] = useState<string | null>(null);
    const [fileName, setFileName] = useState<string | null>(null);
    const [htmlToDisplay, setHtmlToDisplay] = useState<CampaignHtmlTemplate>();
    const navigate = useNavigate();



    const handleTemplatesGetter = useCallback(async () => {
        setLoading(true);
        const idToken = user!.idToken ? user!.idToken : null;
        const response = await fetchData(`/templates`, idToken);
        if (response.status === 200 || response.status === 201) {
            const htmlTemplates = response.data;
            setHtmls(htmlTemplates)
            if (htmlTemplates.length > 0) {
                setSelectedHtml(selected ? selected : htmlTemplates[0].id)
            }
        }

        setLoading(false)

    }, [selected, user]);

    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        event.preventDefault();
        const file = event.target.files?.[0];
        if (file) {
            setFileName(file.name); // Set the file name
            const reader = new FileReader();
            reader.onload = (e) => {
                const content = e.target?.result as string;
                setFileContent(content);
            };
            reader.readAsText(file);
        }

        Toaster.show(`Ficheiro ${file?.name} importado com sucesso. Clique em "+Add" para adicionar`, 'success');
        if (fileInputRef.current) {
            fileInputRef.current.value = ''; // Reset the input value
        }
    };

    const handleAddTemplate = async () => {
        const idToken = user!.idToken ? user!.idToken : null;

        if (!fileContent || !fileName) {
            Toaster.show('Importe um ficheiro Html.', "error");
            return;
        }

        setLoading(true);
        const templateObj = {
            name: fileName,
            html: fileContent,
        }
        const response = await postData(`/templates/`, templateObj, idToken);
        if (response.status === 200 || response.status === 201) {
            const newHtmls = [...htmls, response.data];
            setHtmls(newHtmls);
            setSelectedHtml(response.data.id);
            clearCache(`/templates`);
            Toaster.show(`Template ${fileName} adicionado com sucesso.`, 'success');
        } else {
            Toaster.show('Não foi possível adicionar o template. Tente novamente.', 'error');
        }

        setLoading(false);
        setFileContent(null);
    }



    useEffect(() => {
        const selectedTemplate = htmls.find(html => html.id === selectedHtml);
        if(selectedTemplate){
            setSelectedHtml(selectedTemplate.id);
            setHtmlToDisplay(selectedTemplate);
        } else if(htmls.length > 0) {
            setSelectedHtml(htmls[0].id);
            setHtmlToDisplay(htmls[0]);
        }
    }   , [htmls, selectedHtml])


    useEffect(() => {

            handleTemplatesGetter()
        
    }, [handleTemplatesGetter]);

    return (
        <Page >
            
            <div className='h-full min-h-screen'>
                <div className='flex flex-col gap-8'>
                    <button onClick={() => navigate(-1)} className='flex gap-4 items-center cursor-pointer'>
                        <IoMdArrowRoundBack size={32} title='Go Back' />
                        <p>Go to Builder</p>
                    </button>
                    <div className='flex justify-between gap-10 items-center'>
                        <p className=' text-xl'>Builder Campanha</p>
                        <button onClick={handleAddTemplate} className='w-32 p-2 bg-dashBlue text-white rounded-full text-lg'>
                            Add +
                        </button>
                    </div>
                </div>
                <div className='flex flex-col milecinco:flex-row gap-10 pt-12'>
                    <div className='flex gap-8 flex-col'>
                        <div className='flex flex-col gap-10'>
                            <div className='flex gap-8 items-center'>
                                <div className='flex flex-col gap-4'>
                                    <input
                                        type="file"
                                        accept=".html"
                                        onChange={handleFileChange}
                                        style={{ display: 'none' }}
                                        id="fileInput"
                                        ref={fileInputRef}
                                    />
                                    <button onClick={handleButtonClick} className='w-52 p-3 bg-dashBlue text-white rounded-full text-lg'>
                                        <div className='flex gap-4 justify-center'>
                                            <p>Import</p>
                                            <LuImport size={28} title='Import' />
                                        </div>
                                    </button>
                                </div>
                                <Link to={`${process.env.REACT_APP_MODULES_URL}marketplace`}>
                                    <FaDesktop size={32} title="MarketPlace" />
                                </Link>
                            </div>
                            <div className='flex flex-col mileseis:flex-row gap-8'>
                                <div className='flex flex-col gap-8'>
                                    <p className='text-xl'>Campanha</p>
                                    <div className='scroll overflow-y-auto max-h-[17rem] pr-8 w-80'>
                                        {htmls.length > 0 ? <div className='flex flex-col gap-4'>
                                            {htmls.map((html, index) => (
                                                <button onClick={() => setSelectedHtml(html.id)} key={index} className={`cursor-pointer items-center flex gap-4 px-4 h-16 border-4 border-solid border-black ${selectedHtml === html.id ? "bg-dashBlue text-white" : "bg-zinc-100 text-black"} rounded-3xl w-72 transition-all duration-500 ease-in-out`}>
                                                    <p>Template {html.name}</p>
                                                </button>
                                            ))}
                                        </div> : <p className='text-xl text-center text-zinc-500'>No Templates to display</p>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-col gap-4 w-full'>
                        <div className='flex flex-col gap-4 border-4 border-solid border-black rounded-xl '>
                            <p className='p-4 text-2xl text-center text-dashBlue'>EDITOR HTML</p>
                            <div className='w-full min-h-[40rem] overflow-auto h-full flex justify-center items-center text-3xl'>
                                <div dangerouslySetInnerHTML={{ __html: htmlToDisplay?.html || "" }} />
                            </div>
                        </div>
                        <p className='text-xl text-center'>Template {htmlToDisplay?.name}</p>
                    </div>
                </div>
                <Loading isOpen={loading} />
            </div>
        </Page>
    );
}