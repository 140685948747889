import React, { ReactNode } from 'react';
import Header from './Header';
import Footer from './Footer';



export default function Page({ children }: { children: ReactNode }) {


    return (
        <div className='w-full h-screen'>
                <Header />
                <div className='w-full'>
                    <div className=' bg-white h-full'>
                        <div className='p-4 mil:p-10'>{children}</div>
                        <Footer />
                    </div>
                </div>
        </div>
    );
}
