





const Footer = () => {
    const lang = navigator.language || 'en';

    // TODO: Add proper the links

    return (

        <div className=" bg-zinc-100 p-10">
            <div className="flex flex-col mil:flex-row justify-evenly gap-6">
                <div className="flex flex-col justify-end items-center gap-2">
                    <p className="text-xl">Recursos Úteis</p>
                    <p className="text-sm text-zinc-400">Guia de Programador</p>
                </div>
                <div className="flex flex-col justify-end items-center gap-2">
                    <p className="text-xl">Ferramentas Úteis</p>
                    <p className="text-sm text-zinc-400">Google Analytics</p>
                </div>
                <div className="flex flex-col justify-end items-center gap-2">
                    <p className="text-xl">Precisa de Ajuda?</p>
                    <p className="text-sm text-zinc-400">Contacte-nos</p>
                </div>
            </div>
            <p className="text-center text-zinc-400 pt-10">&copy; 2024 Google - Contrato de programador - Política de Privacidade da Google</p>
        </div>

    )




}

export default Footer;
