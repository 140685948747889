import React from 'react';
import { Styles } from '../../interfaces/Styles';
import { Inputs } from '../../interfaces/Inputs';
import styled from 'styled-components';


export default function HTMLComponent({ styles, inputs }: { styles: Styles, inputs: Inputs }) {

    const GridContainer = styled.div`
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1, minmax(0, 1fr));
        gap: 2rem;

`;

    const SubscribeButton = styled.button`
        width: 200px;
        height: 50px;
        cursor: pointer;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        padding-left: 1.5rem;
        padding-right: 1.5rem;
        font-weight: 700;
        font-size: 1.25rem;
        line-height: 1.75rem;
        transition: all 300ms ease-in-out;
        background-color: ${styles.button.backgroundColor};
        color: ${styles.button.textColor};
        border-radius: ${styles.button.roundeness}px;
        border-width: ${styles.button.borderSize}px;
        border-color: ${styles.button.borderColor};

        &:hover {
            background-color: ${styles.button.backgroundColorHover};
            color: ${styles.button.textColorHover};
        }
`;

    const StyledCheckbox = styled.input.attrs({ type: 'checkbox' })`
        width: 1.25rem;
        height: 1.25rem;
        cursor: pointer;
        appearance: none;
        background-color: #fff;
        border: 1px solid #ccc;
        position: relative;

        &:checked {
            background-color: ${styles.checkBox.checkedColor};
            border: 1px solid ${styles.checkBox.checkedColor};
        }

        &:not(:checked) {
            background-color: ${styles.checkBox.uncheckedColor};
            border: 1px solid ${styles.checkBox.uncheckedColor};
        }

        &:checked::before {
            content: '';
            position: absolute;
            top: 0;
            left: 0.25rem;
            width: 0.5rem;
            height: 0.75rem;
            border: solid white;
            border-width: 0 0.2rem 0.2rem 0;
            transform: rotate(45deg);
        }
        `;


    return (
        <div style={{
            width: '100%',
            paddingTop: '3.5rem',
            paddingLeft: '1rem',
            paddingRight: '1rem',
            paddingBottom: '1rem',
            position: 'relative'
        }}>
            <div style={{
                width: '8rem',
                top: 0,
                left: '50%',
                transform: 'translateX(-50%)',
                aspectRatio: 1,
                borderRadius: '9999px',
                backgroundColor: '#525252',
                position: 'absolute',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}>                <div style={{ borderWidth: "4px", borderRadius: "9999px", padding: "1rem" }}>
                    <img style={{ width: "5rem", filter: "invert(1)" }} src="data:image/svg+xml,%3Csvg%20width%3D%22800px%22%20height%3D%22800px%22%20viewBox%3D%220%200%2032%2032%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlnsXlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%3Ctitle%3Eenvelope-open%3C%2Ftitle%3E%3Cdesc%3ECreated%20with%20Sketch%20Beta.%3C%2Fdesc%3E%3Cdefs%3E%3C%2Fdefs%3E%3Cg%20id%3D%22Page-1%22%20stroke%3D%22none%22%20stroke-width%3D%221%22%20fill%3D%22none%22%20fill-rule%3D%22evenodd%22%3E%3Cg%20id%3D%22Icon-Set%22%20transform%3D%22translate(-516.000000%2C%20-255.000000)%22%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M532%2C275.656%20L518%2C265%20L531.98%2C257.331%20L546%2C265%20L532%2C275.656%20L532%2C275.656%20Z%20M546%2C283%20C546%2C283.203%20545.961%2C283.395%20545.905%2C283.578%20L536.935%2C274.607%20L546%2C267.375%20L546%2C283%20L546%2C283%20Z%20M519.556%2C284.946%20L528.742%2C275.76%20L532%2C278%20L535.258%2C275.76%20L544.444%2C284.946%20C544.301%2C284.979%20519.699%2C284.979%20519.556%2C284.946%20L519.556%2C284.946%20Z%20M518%2C283%20L518%2C267.375%20L527.066%2C274.607%20L518.095%2C283.578%20C518.039%2C283.395%20518%2C283.203%20518%2C283%20L518%2C283%20Z%20M532%2C255%20L516%2C264%20L516%2C283%20C516%2C285.209%20517.791%2C287%20520%2C287%20L544%2C287%20C546.209%2C287%20548%2C285.209%20548%2C283%20L548%2C264%20L532%2C255%20L532%2C255%20Z%22%20id%3D%22envelope-open%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fg%3E%3C%2Fsvg%3E" alt="mail" />
                </div>
            </div>
            <div
                style={{
                    padding: '5rem',
                    borderRadius: styles.mainCard.roundeness,
                    backgroundColor: styles.mainCard.backgroundColor,
                    color: styles.mainCard.text.color,
                    fontSize: styles.mainCard.text.size,
                    fontFamily: styles.mainCard.text.font
                }}
            >
                <div style={{ display: "flex", flexDirection: "column", textAlign: "center", paddingBottom: "2.5rem" }}>
                    <p style={{ fontSize: "1.875rem", lineHeight: "2.25rem", fontWeight: "700", paddingTop: "1.5rem", paddingBottom: "1.5rem", margin: "0" }}>Newsletter</p>
                    <p style={{ fontSize: "1.25rem", lineHeight: "1.75rem", fontWeight: "700", margin: "0" }}>Subscribe to our newsletter</p>
                    <p style={{ fontSize: "0.875rem", lineHeight: "1.25rem", margin: "0" }} >Subscribe to our newsletter and get the latest news and updates.</p>
                </div>
                <GridContainer>
                    <div style={{ display: "flex", flexDirection: "column", gap: "2.5rem", justifyContent: "center", alignItems: "center", width: "100%" }}>
                        {inputs.name && (
                            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", width: "100%", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: "600px", alignItems: "flex-start", gap: "0.5rem" }}>
                                    <label style={{
                                        display: 'block',
                                        fontWeight: 'bold',
                                        textAlign: 'left',
                                    }}>Name</label>
                                    <input type="text"
                                        name='name'
                                        placeholder='Enter your name'
                                        style={{
                                            width: '100%',
                                            paddingLeft: '1rem',
                                            paddingRight: '1rem',
                                            paddingTop: '0.5rem',
                                            paddingBottom: '0.5rem',
                                            borderRadius: '0.5rem',
                                            borderWidth: '1px',
                                            borderColor: '#d1d5db',
                                        }}
                                    />
                                </div>
                            </div>
                        )}
                        {inputs.email && (
                            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", width: "100%", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: "600px", alignItems: "flex-start", gap: "0.5rem" }}>
                                    <label style={{
                                        display: 'block',
                                        fontWeight: 'bold',
                                        textAlign: 'left',
                                    }}>Email</label>
                                    <input
                                        name='email'
                                        id='email'
                                        type="email"
                                        placeholder='Enter your email'
                                        style={{
                                            width: '100%',
                                            paddingLeft: '1rem',
                                            paddingRight: '1rem',
                                            paddingTop: '0.5rem',
                                            paddingBottom: '0.5rem',
                                            borderRadius: '0.5rem',
                                            borderWidth: '1px',
                                            borderColor: '#d1d5db',
                                        }} />
                                </div>
                            </div>
                        )}
                        {inputs.last_name && (
                            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", width: "100%", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: "600px", alignItems: "flex-start", gap: "0.5rem" }}>
                                    <label style={{
                                        display: 'block',
                                        fontWeight: 'bold',
                                        textAlign: 'left',
                                    }}>Last Name</label>
                                    <input type="text"
                                        id='lastname'
                                        placeholder='Enter your last name'
                                        style={{
                                            width: '100%',
                                            paddingLeft: '1rem',
                                            paddingRight: '1rem',
                                            paddingTop: '0.5rem',
                                            paddingBottom: '0.5rem',
                                            borderRadius: '0.5rem',
                                            borderWidth: '1px',
                                            borderColor: '#d1d5db',
                                        }} />
                                </div>
                            </div>
                        )}
                        {inputs.phone && (
                            <div style={{ display: "flex", flexDirection: "column", gap: "0.5rem", width: "100%", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: "600px", alignItems: "flex-start", gap: "0.5rem" }}>
                                    <label style={{
                                        display: 'block',
                                        fontWeight: 'bold',
                                        textAlign: 'left',
                                    }}>Phone</label>
                                    <input
                                        id='phone'
                                        type="text"
                                        name='phone'
                                        placeholder='Enter your phone number'
                                        style={{
                                            width: '100%',
                                            paddingLeft: '1rem',
                                            paddingRight: '1rem',
                                            paddingTop: '0.5rem',
                                            paddingBottom: '0.5rem',
                                            borderRadius: '0.5rem',
                                            borderWidth: '1px',
                                            borderColor: '#d1d5db',
                                        }} />
                                </div>
                            </div>
                        )}
                        {inputs.personalized && inputs.personalized_list.map((item, index) => (
                            <div key={index} style={{ display: "flex", flexDirection: "column", gap: "0.5rem", width: "100%", alignItems: "center" }}>
                                <div style={{ display: "flex", flexDirection: "column", width: "100%", maxWidth: "600px", alignItems: "flex-start", gap: "0.5rem" }}>
                                    <label style={{
                                        display: 'block',
                                        fontWeight: 'bold',
                                        textAlign: 'left',
                                    }}>{item}</label>
                                    <input id={item} type="text" name={item}
                                        placeholder={`Enter ${item.toLowerCase()}`}
                                        style={{
                                            width: '100%',
                                            paddingLeft: '1rem',
                                            paddingRight: '1rem',
                                            paddingTop: '0.5rem',
                                            paddingBottom: '0.5rem',
                                            borderRadius: '0.5rem',
                                            borderWidth: '1px',
                                            borderColor: '#d1d5db',
                                        }} />
                                </div>
                            </div>
                        ))}
                    </div>
                    <div style={{ display: 'flex', gap: '8px', flexWrap: 'wrap' }}>
                        {inputs.checkbox && inputs.checkbox_list.map((item, index) => (
                            <div key={index} style={{ display: 'flex', alignItems: 'center', marginRight: '0.75rem' }}>
                                <StyledCheckbox name={`checkbox-${item}`} />
                                <label className='font-bold' style={{ marginLeft: '0.5rem' }}>{item}</label>
                            </div>
                        ))}
                    </div>
                </GridContainer>
                <div style={{ paddingTop: '5rem', display: 'flex', justifyContent: 'center' }}>
                    <SubscribeButton id='subscribe'>
                        Subscribe
                    </SubscribeButton>
                </div>
            </div>
        </div>
    );
}
