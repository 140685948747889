

import { initializeApp } from "firebase/app";
import { getAuth, onAuthStateChanged, signInWithEmailAndPassword } from "firebase/auth";
import { useUser } from "../contexts/userContext";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: process.env.REACT_APP_AUTH_DOMAIN,
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
    measurementId: process.env.REACT_APP_MEASUREMENT_ID
}
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export async function signInWithEmailPassword(email: string, password: string) {
    try {
        const userCredential = await signInWithEmailAndPassword(auth, email, password);
        const user = userCredential.user;
        return {message:"success", idToken: await user.getIdToken(), refreshToken: user.refreshToken};
    } catch (error) {
        console.error(error);
        return {message:error, idToken: null};
    }
}




// export async function signInWithGoogle() {
//     try {
//         const userCredential = await signInWithPopup(auth, provider);
//         const idToken = await userCredential.user.getIdToken();

//         return idToken;
//     } catch (error) {
//         console.error(error);
//         return null;
//     }
// }



// export async function sendResetPasswordEmail(email: string) {
//     try {
//         await sendPasswordResetEmail(auth, email);
//         return "Password reset email sent successfully";
//     } catch (error) {
//         return (error as Error).message;
//     }
// }

// export async function uploadToFirebase(files: File[], path: string, email: string) {
//     try {
//         const storage = getStorage(app);
//         const uploadPromises = files.map(async (file) => {
//             const newFileName = `${email}_${file.name}`; // Add email to file name to make it unique
//             const storageRef = ref(storage, `${path}/${newFileName}`);

//             await uploadBytes(storageRef, file);
//             const url = await getDownloadURL(storageRef);
//             return url;
//         });

//         const snapshots = await Promise.all(uploadPromises);

//         return snapshots;
//     } catch (error) {
//         console.error(error);
//         return null;
//     }
// }