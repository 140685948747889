import { useState } from 'react';
import modules from '../content/images/modules.png';
import visionD from '../content/images/visionD.png';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import Loading from '../components/Loading/loading';
import { toast } from 'react-hot-toast';
import { signInWithEmailPassword } from '../firebase/firebase';
import { User } from '../interfaces/User';
import { useUser } from '../contexts/userContext';
import { validateEmail } from '../components/ValidationUtils';
import eyeClose from '../content/images/eyeClose.svg';
import eyeOpen from '../content/images/eyeOpen.svg';


export default function Login() {

    const navigate = useNavigate();
    const { setUser } = useUser();
    const [loading, setLoading] = useState(false);
    // const [remember, setRemember] = useState(JSON.parse(localStorage.getItem('remember') || 'false'));
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const [signUp, setSignUp] = useState(false);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [name, setName] = useState('');
    const [apikey, setApiKey] = useState('');


    function togglePasswordVisibility() {
        setIsPasswordVisible((prevState) => !prevState);
    }
    const handleLogin = async () => {
        if (!email || !password) {
            toast.error('Preencha todos os campos');
            return;
        }
        if (!validateEmail(email)) {
            toast.error('Email inválido.');
            return;
        }

        setLoading(true);
        try {
            const { message, idToken, refreshToken } = await signInWithEmailPassword(email, password);

            if (message === 'success') {
                const user: User = {
                    email: email,
                    idToken: idToken!,
                    refreshToken: refreshToken!,
                };
                setUser(user);
                sessionStorage.setItem('user', JSON.stringify(user));
                navigate('/campaigns');
            } else {
                toast.error('Credenciais inválidas. Tente novamente.');
                console.error('Login failed');
            }
        } catch (error) {
            toast.error('Credenciais inválidas. Tente novamente.');
            console.error('Login failed', error);
        }
        setLoading(false);
    }



    const handleSignUp = async () => {

        setLoading(true);
        try {
            const response = await axios.post(`${process.env.REACT_APP_API_URL}/register`, {
                email: email,
                password: password,
                name: name,
                api_key: apikey
            });

            if (response.status === 200 || response.status === 201) {
                setSignUp(false);
                toast.success('Usuário criado com sucesso');
            } else {
                toast.error('Credenciais inválidas. Tente novamente.');
                console.error('Login failed');
            }
        } catch (error) {
            toast.error('Credenciais inválidas. Tente novamente.');
            console.error('Login failed', error);
        }
        setLoading(false);
    };


    return (
        <div className='flex w-full h-full min-h-screen mil:bg-white bg-[var(--primary)] items-center overflow-hidden'>

            <div className={`${signUp ? "w-0 invisible opacity-0 mil:hidden" : "w-full opacity-100 mil:w-[45%]"} transition-all duration-300 ease-out  text-black dark-bg-image h-full`}>
                <div className={`flex flex-col justify-center h-full gap-8 px-2 cinco:px-10 mile400:px-15`}>
                    <div className='m-auto'>
                        <img src={visionD} alt="logo" className='w-[20rem] text-left hidden mil:block' />
                        <div className='mil:hidden flex flex-col gap-2'>
                            <img src={modules} alt="logo" className='w-[20rem] text-left' />
                            <p className=' text-center text-white text-3xl'>NEWSLETTER</p>
                            <p className='text-white text-sm text-center'>Powered by VisionD</p>
                        </div>
                    </div>
                    <div className='px-5 cinco:px-10 mile400:px-15'>
                        <div className='flex flex-col gap-6'>
                            <div className='flex flex-col gap-2'>
                                <input
                                    className="w-full px-4 py-2 text-lg border border-gray-300 border-solid rounded-full"
                                    type="text"
                                    placeholder='| Email'
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </div>
                            <div className="relative">
                                <button
                                    tabIndex={-1}
                                    type='button'
                                    className="absolute right-0 flex items-center px-4 text-gray-600 translate-y-3"
                                    onClick={togglePasswordVisibility}
                                >
                                    {!isPasswordVisible ? (
                                        <img src={eyeClose} alt="Not visible" className="w-5 h-5" />
                                    ) : (
                                        <img src={eyeOpen} alt="Visible" className="w-5 h-5" />
                                    )}
                                </button>
                                <div className='flex flex-col gap-2'>
                                    {/* <label className="text-lg font-semibold text-white mil:text-black">Password</label> */}
                                    <input
                                        type={isPasswordVisible ? "text" : "password"}
                                        placeholder='| Password'
                                        className="w-full px-4 py-2 text-lg border border-gray-300 border-solid rounded-full"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                    />
                                </div>
                            </div>
                            <button onClick={handleLogin} className="font-bold w-full text-white bg-dashBlue hover:bg-dashBlueDark focus:outline-none focus:ring-4 focus:ring-blue-300 rounded-xl text-2xl px-5 py-3.5 text-center">Login</button>
                            <div className='m-auto'>
                                <div className="flex flex-col gap-4 text-center">
                                    <div className="flex gap-4 text-lg font-semibold text-center text-white mil:text-black">
                                        <p>Or</p>
                                        <div className='hover:underline cursor-pointer underline-offset-4 text-blue-400' onClick={() => setSignUp(true)}>
                                            Sign Up
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex mil:hidden justify-end pt-20 px-5  cinco:px-10 mile400:px-15'>
                        <p className='w-44 text-end text-white text-2xl'>INTEGRATION MADE EASY.</p>
                    </div>
                </div>
            </div>

            <div className={`${!signUp ? "w-0 opacity-0 hidden" : "w-full opacity-100 mil:translate-x-[120%]"} transition-all duration-500 ease-in  text-black dark-bg-image mil:w-[45%] h-full`}>
                <div className={`flex flex-col justify-center  h-full gap-8 px-2 cinco:px-10 mile400:px-15`}>
                    <div className='m-auto'>
                        <img src={visionD} alt="logo" className='w-[20rem] text-left hidden mil:block' />
                        <div className='mil:hidden flex flex-col gap-2'>
                            <img src={modules} alt="logo" className='w-[20rem] text-left' />
                            <p className=' text-center text-white text-3xl'>NEWSLETTER</p>
                            <p className='text-white text-sm text-center'>Powered by VisionD</p>
                        </div>
                    </div>
                    <div className='px-5 cinco:px-10 mile400:px-15'>
                        <div className='flex flex-col gap-6'>
                            <div className='flex flex-col gap-2'>
                                <input
                                    className="w-full px-4 py-2 text-lg border border-gray-300 border-solid rounded-full"
                                    type="text"
                                    placeholder='| Api Key'
                                    onChange={(e) => setApiKey(e.target.value)}
                                    value={apikey}
                                />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <input
                                    className="w-full px-4 py-2 text-lg border border-gray-300 border-solid rounded-full"
                                    type="text"
                                    placeholder='| Name'
                                    onChange={(e) => setName(e.target.value)}
                                    value={name}
                                />
                            </div>
                            <div className='flex flex-col gap-2'>
                                <input
                                    className="w-full px-4 py-2 text-lg border border-gray-300 border-solid rounded-full"
                                    type="text"
                                    placeholder='| Email'
                                    onChange={(e) => setEmail(e.target.value)}
                                    value={email}
                                />
                            </div>
                            <div className="relative">
                                <button
                                    tabIndex={-1}
                                    type='button'
                                    className="absolute right-0 flex items-center px-4 text-gray-600 translate-y-3"
                                    onClick={togglePasswordVisibility}
                                >
                                                                        {!isPasswordVisible ? (
                                        <img src={eyeClose} alt="Not visible" className="w-5 h-5" />
                                    ) : (
                                        <img src={eyeOpen} alt="Visible" className="w-5 h-5" />
                                    )}
                                </button>
                                <div className='flex flex-col gap-2'>
                                    <input
                                        type={isPasswordVisible ? "text" : "password"}
                                        placeholder='| Password'
                                        className="w-full px-4 py-2 text-lg border border-gray-300 border-solid rounded-full"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                    />
                                </div>
                            </div>
                            <button onClick={handleSignUp} className="font-bold w-full text-white bg-dashBlue hover:bg-dashBlueDark focus:outline-none focus:ring-4 focus:ring-blue-300 rounded-xl text-2xl px-5 py-3.5 text-center">Sign Up</button>
                            <div className='m-auto'>
                                <div className="flex flex-col gap-4 text-center">
                                    <div className="flex gap-4 text-lg font-semibold text-center text-white mil:text-black">
                                        <div className='hover:underline cursor-pointer underline-offset-4 text-blue-400' onClick={() => setSignUp(false)}>
                                            Já tenho uma conta
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='w-full flex mil:hidden justify-end pt-20 px-5  cinco:px-10 mile400:px-15'>
                        <p className='w-44 text-end text-white text-2xl'>INTEGRATION MADE EASY.</p>
                    </div>
                </div>
            </div>
            <div className={`relative items-center justify-center transition-all duration-700 ease-in-out ${signUp ? "translate-x-[-85%]" : ""} w-[55%] min-h-screen h-full hidden mil:flex bg-[var(--primary)]`}>
                <div className='flex items-center justify-center h-full w-[30rem] flex-col gap-4'>
                    <img src={modules} alt="Modules" />
                    <p className='text-end text-white text-3xl'>NEWSLETTER</p>
                </div>
                <p className='absolute bottom-20 right-20 w-44 text-end text-white text-2xl'>INTEGRATION MADE EASY.</p>
            </div>
            <Loading isOpen={loading} />
        </div>
    );
}