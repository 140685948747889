import React from 'react';
import "../../components/customScroll.styles.scss";
import { useNavigate } from 'react-router-dom';
import { Campaign } from '../../interfaces/Campaign';
import Toggle from '../../components/Toggle/Toggle';
import { BsDatabase } from "react-icons/bs";

export default function CampaignListView({ filteredcampaigns, setfilteredcampaigns }: { filteredcampaigns: Campaign[]; setfilteredcampaigns: React.Dispatch<React.SetStateAction<Campaign[]>> }) {
    const lang = navigator.language || 'en';
    const navigate = useNavigate();


    return (
        <div className='scroll overflow-auto max-h-[40rem] w-full'>
            <div className=' w-full'>
                <table className=' w-full'>
                    <thead >
                        <tr className='text-sm text-left text-zinc-500 '>
                            <th className="px-6 py-3">
                                {lang === "pt" ? "Número" : "Number"}
                            </th>
                            <th className="px-6 py-3  min-w-[8rem]">
                                {lang === "pt" ? "Nome" : "Name"}
                            </th>
                            <th className="px-6 py-3  min-w-[10rem]">
                                {lang === "pt" ? "Data de Criação" : "Criation Date"}
                            </th>
                            <th className="px-6 py-3  min-w-[10rem]">
                                {lang === "pt" ? "Última Atualização" : "Last Update"}
                            </th>
                            <th className="px-6 py-3  min-w-[10rem]">
                                {lang === "pt" ? "Base de Dados" : "Data Bases"}
                            </th>
                            <th className="px-6 py-3  min-w-[10rem]">
                                {"Template Newslette (HTML)"}
                            </th>
                            <th className="px-6 py-3 ">
                                {lang === "pt" ? "Estado" : "State"}
                            </th>
                            <th className="px-6 py-3 ">
                                {lang === "pt" ? "Ações" : "Actions"}
                            </th>
                        </tr>
                    </thead>
                    <tbody className="">
                        {filteredcampaigns.map((elem, index) => (
                            <tr key={index} className={` text-zinc-500  border-t-2 border-zinc-200`}>
                                <td className="px-6 py-10">
                                    <div className="text-sm font-bold text-black rounded-2xl ">{elem.number}</div>
                                </td>
                                <td className="px-6 py-10 max-w-56 break-words">
                                    <div className="text-sm font-bold text-black rounded-2xl ">{elem.name}</div>
                                </td>
                                <td className="px-6 py-10  ">
                                    <div className="text-sm rounded-2xl ">{elem.createdat}</div>
                                </td>
                                <td className="px-6 py-10  ">
                                    <div className="text-sm rounded-2xl ">{elem.updatedat}</div>
                                </td>
                                <td className="px-6 py-10">
                                    {elem?.lists !== null ? <div className='flex flex-col  gap-4  items-center'>        <BsDatabase size={28} title="Data Base" />
                                        <div className="text-sm rounded-2xl ">{elem?.lists.join(", ")}</div></div>
                                        : <div className="text-sm rounded-2xl ">Não Atribuídas</div>}
                                </td>
                                <td className="px-6 py-10  ">
                                    <div className="text-sm rounded-2xl ">{elem.template_id !== null ? elem.template_id : "Não Atribuído"}</div>
                                </td>
                                <td className="px-6 py-10  ">
                                    <Toggle index={index} status={elem.state === "active"} values={filteredcampaigns} setValue={setfilteredcampaigns} />

                                </td>
                                <td className="px-6 py-10 text-sm font-medium">
                                    <button onClick={() => navigate(elem.name, { state: { id: elem.id, name: elem.name, color: elem.color, number: elem.number, state: elem.state } })} className="text-sm font-bold text-indigo-600 hover:text-indigo-900">
                                        Open
                                    </button>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

