import { Styles } from "../../interfaces/Styles";
import "react-toggle/style.css"
import { useRef, useState } from "react";
import { IoIosClose } from "react-icons/io";
import { HexColorPicker } from "react-colorful";
import { BsTransparency } from "react-icons/bs";
import Modal from "../../components/Modal";
import { Transition } from "react-transition-group";


export default function StylesComponent({ styles, setStyles }: { styles: Styles, setStyles: React.Dispatch<React.SetStateAction<Styles>> }) {
    const [visibilityMap, setVisibilityMap] = useState<{ [key: string]: boolean }>({});


    const setRoundenessMain = (value: string) => {
        if (value === '') return setStyles({ ...styles, mainCard: { ...styles.mainCard, roundeness: 0 } })
        const intValue = parseInt(value)

        if (!isNaN(intValue)) {
            if (intValue >= 0 && intValue <= 100)
                setStyles({ ...styles, mainCard: { ...styles.mainCard, roundeness: intValue } })

        }
    }

    const setTextSizeMain = (value: string) => {
        if (value === '') return setStyles({ ...styles, mainCard: { ...styles.mainCard, text: { ...styles.mainCard.text, size: 0 } } })
        const intValue = parseInt(value)

        if (!isNaN(intValue)) {
            if (intValue >= 0 && intValue <= 100)
                setStyles({ ...styles, mainCard: { ...styles.mainCard, text: { ...styles.mainCard.text, size: intValue } } })

        }
    }

    const setRoundenessButtons = (value: string) => {
        if (value === '') return setStyles({ ...styles, button: { ...styles.button, roundeness: 0 } })
        const intValue = parseInt(value)

        if (!isNaN(intValue)) {
            if (intValue >= 0 && intValue <= 100)
                setStyles({ ...styles, button: { ...styles.button, roundeness: intValue } })

        }
    }

    const setBorderButtons = (value: string) => {
        if (value === '') return setStyles({ ...styles, button: { ...styles.button, borderSize: 0 } })
        const intValue = parseInt(value)

        if (!isNaN(intValue)) {
            if (intValue >= 0 && intValue <= 100)
                setStyles({ ...styles, button: { ...styles.button, borderSize: intValue } })

        }
    }


    const updateVisibility = (key: string, value: boolean) => {
        setVisibilityMap(prev => ({ ...prev, [key]: value }));
    };

    const openPicker = (key: string) => {
        updateVisibility(key, true);
    };

    const closePicker = (key: string) => {
        updateVisibility(key, false);
    };

    const ColorPickerComponent = ({ pickerName, color, setColor }: { pickerName: string, color: string, setColor: React.Dispatch<React.SetStateAction<string>> }) => {

        return (


            <div className={`fixed inset-0 z-50 flex items-center justify-center`}>
                <div className="bg-zinc-200 rounded-2xl p-2 flex flex-col items-end shadow-2xl">
                    <div className="flex justify-between w-full items-center py-2">
                        <BsTransparency onClick={() => setColor("transparent")} className='cursor-pointer' size={28} title={"Transparent"} />
                        <IoIosClose onClick={() => closePicker(pickerName)} className='cursor-pointer' size={32} title={"Close"} />
                    </div>
                    <HexColorPicker color={color} onChange={(color) => setColor(color)} />
                </div>
            </div>

        );
    };




    return (
        <div className="py-10 flex flex-col gap-10">
            <div className="bg-zinc-100 rounded-xl p-4">
                <p className="text-xl font-bold">Main Card</p>
                <div className="flex flex-col gap-4 p-4">
                    <div className="flex  gap-4 items-center">
                        <p className="text-lg">Roundeness:</p>
                        <input
                            className="outline-none border-2 border-black rounded-full p-2 w-[4rem] text-center bg-zinc-100 h-8 font-bold"
                            type="text"
                            value={styles.mainCard.roundeness}
                            onChange={(e) => setRoundenessMain(e.target.value)}
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-4 p-4">
                    <div className="flex gap-4 items-center">
                        <p className="text-lg">Background Color:</p>
                        <button onClick={() => { openPicker("mainCard") }} className="flex gap-4 items-center cursor-pointer">
                            <p className="font-bold">{styles.mainCard.backgroundColor}</p>
                            <div style={{ backgroundColor: styles.mainCard.backgroundColor }} className={`w-5 aspect-square border-2 border-black`}></div>
                        </button>
                        <ColorPickerComponent pickerName="mainCard" color={styles.mainCard.backgroundColor} setColor={(color) => setStyles({ ...styles, mainCard: { ...styles.mainCard, backgroundColor: color as string } })} />
                    </div>
                </div>
                <div className="flex flex-col gap-3 p-4">
                    <div className="flex flex-col gap-3">
                        <p className="text-lg">Text</p>
                        <div className="flex gap-6 flex-wrap px-4">
                            <div className="flex gap-4">
                                <div className="flex items-end">
                                    <p className="text-xs">A</p>
                                    <p className="text-xl">A</p>
                                </div>
                                <input
                                    className="outline-none border-2 border-black rounded-full p-2 w-[4rem] text-center bg-zinc-100 h-8 font-bold"
                                    type="text"
                                    value={styles.mainCard.text.size}
                                    onChange={(e) => setTextSizeMain(e.target.value)}
                                />
                            </div>
                            <div className="flex gap-4">
                                <p className="text-lg">Font</p>
                                <input
                                    className="outline-none border-2 border-black rounded-full p-2 w-44 text-center bg-zinc-100 h-8 font-bold"
                                    type="text"
                                    value={styles.mainCard.text.font}
                                />
                            </div>
                            <div className="flex gap-4 items-center">
                                <button onClick={() => { openPicker("mainCardTextColor"); }} className="flex gap-4 items-center cursor-pointer">
                                    <p className="font-bold">{styles.mainCard.text.color}</p>
                                    <div style={{ backgroundColor: styles.mainCard.text.color }} className={`w-5 aspect-square border-2 border-black`}>
                                    </div>
                                </button>

                                <ColorPickerComponent pickerName="mainCardTextColor" color={styles.mainCard.text.color} setColor={(color) => setStyles({ ...styles, mainCard: { ...styles.mainCard, text: { ...styles.mainCard.text, color: color as string } } })} />


                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="bg-zinc-100 rounded-xl p-4">
                <p className="text-xl font-bold">CheckBox</p>
                <div className="flex flex-wrap gap-8 p-4">
                    <div className="flex gap-4 items-center">
                        <p>Checked</p>
                        <button onClick={() => { openPicker("checkedColor"); }} className="flex gap-4 items-center cursor-pointer">
                            <p className="font-bold">{styles.checkBox.checkedColor}</p>
                            <div style={{ backgroundColor: styles.checkBox.checkedColor }} className={`w-5 aspect-square border-2 border-black`}>
                            </div>
                        </button>
                        <ColorPickerComponent pickerName="checkedColor" color={styles.checkBox.checkedColor} setColor={(color) => setStyles({ ...styles, checkBox: { ...styles.checkBox, checkedColor: color as string } })} />

                    </div>
                    <div className="flex gap-4 items-center">
                        <p>Unchecked</p>
                        <button onClick={() => { openPicker("uncheckedColor"); }} className="flex gap-4 items-center cursor-pointer">
                            <p className="font-bold">{styles.checkBox.uncheckedColor}</p>
                            <div style={{ backgroundColor: styles.checkBox.uncheckedColor }} className={`w-5 aspect-square border-2 border-black`}>
                            </div>
                        </button>
                        <ColorPickerComponent pickerName="uncheckedColor" color={styles.checkBox.uncheckedColor} setColor={(color) => setStyles({ ...styles, checkBox: { ...styles.checkBox, uncheckedColor: color as string } })} />

                    </div>
                </div>

            </div>
            <div className="bg-zinc-100 rounded-xl p-4 w-full">
                <p className="text-xl font-bold">Buttons</p>
                <div className="flex flex-col gap-4 p-4 ">
                    <div className="flex  gap-4 items-center">
                        <p className="text-lg">Border:</p>
                        <input
                            className="outline-none border-2 border-black rounded-full p-2 w-[4rem] text-center bg-zinc-100 h-8 font-bold"
                            type="text"
                            value={styles.button.borderSize}
                            onChange={(e) => setBorderButtons(e.target.value)}
                        />
                        <button onClick={() => { openPicker("buttonBorderColor"); }} className="flex gap-4 items-center cursor-pointer">
                            <p className="font-bold">{styles.button.borderColor}</p>
                            <div style={{ backgroundColor: styles.button.borderColor }} className={`w-5 aspect-square border-2 border-black`}>
                            </div>
                        </button>
                        <ColorPickerComponent pickerName="buttonBorderColor" color={styles.button.borderColor} setColor={(color) => setStyles({ ...styles, button: { ...styles.button, borderColor: color as string } })} />

                    </div>
                    <div className="flex  gap-4 items-center">
                        <p className="text-lg">Roundeness:</p>
                        <input
                            className="outline-none border-2 border-black rounded-full p-2 w-[4rem] text-center bg-zinc-100 h-8 font-bold"
                            type="text"
                            value={styles.button.roundeness}
                            onChange={(e) => setRoundenessButtons(e.target.value)}
                        />
                    </div>
                </div>
                <div className="flex flex-col gap-4 p-4">
                    <div className="flex flex-col gap-4">
                        <p className="text-lg">Background</p>
                        <div className="flex gap-8 flex-wrap px-4">
                            <div className="flex gap-4">
                                <div className="flex items-center">
                                    <p className="text-lg">Normal:</p>
                                </div>
                                <div className="flex gap-4 items-center">
                                    <button onClick={() => { openPicker("button"); }} className="flex gap-4 items-center cursor-pointer">
                                        <p className="font-bold">{styles.button.backgroundColor}</p>
                                        <div style={{ backgroundColor: styles.button.backgroundColor }} className={`w-5 aspect-square border-2 border-black`}>
                                        </div>
                                    </button>
                                    <ColorPickerComponent pickerName="button" color={styles.button.backgroundColor} setColor={(color) => setStyles({ ...styles, button: { ...styles.button, backgroundColor: color as string } })} />

                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div className="flex items-center">
                                    <p className="text-lg">Hover:</p>
                                </div>
                                <div className="flex gap-4 items-center">
                                    <button onClick={() => { openPicker("buttonHover"); }} className="flex gap-4 items-center cursor-pointer">
                                        <p className="font-bold">{styles.button.backgroundColorHover}</p>
                                        <div style={{ backgroundColor: styles.button.backgroundColorHover }} className={`w-5 aspect-square border-2 border-black`}>
                                        </div>
                                    </button>
                                    <ColorPickerComponent pickerName="buttonHover" color={styles.button.backgroundColorHover} setColor={(color) => setStyles({ ...styles, button: { ...styles.button, backgroundColorHover: color as string } })} />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="flex flex-col gap-4">
                        <p className="text-lg">Text</p>
                        <div className="flex gap-8 flex-wrap px-4">
                            <div className="flex gap-4">
                                <div className="flex items-center">
                                    <p className="text-lg">Normal:</p>
                                </div>
                                <div className="flex gap-4 items-center">
                                    <button onClick={() => { openPicker("text"); }} className="flex gap-4 items-center cursor-pointer">
                                        <p className="font-bold">{styles.button.textColor}</p>
                                        <div style={{ backgroundColor: styles.button.textColor }} className={`w-5 aspect-square border-2 border-black`}>
                                        </div>
                                    </button>
                                    <ColorPickerComponent pickerName="text" color={styles.button.textColor} setColor={(color) => setStyles({ ...styles, button: { ...styles.button, textColor: color as string } })} />

                                </div>
                            </div>
                            <div className="flex gap-4">
                                <div className="flex items-center">
                                    <p className="text-lg">Hover:</p>
                                </div>
                                <div className="flex gap-4 items-center">
                                    <button onClick={() => { openPicker("textHover"); }} className="flex gap-4 items-center cursor-pointer">
                                        <p className="font-bold">{styles.button.textColorHover}</p>
                                        <div style={{ backgroundColor: styles.button.textColorHover }} className={`w-5 aspect-square border-2 border-black`}>
                                        </div>
                                    </button>
                                    <ColorPickerComponent pickerName="textHover" color={styles.button.textColorHover} setColor={(color) => setStyles({ ...styles, button: { ...styles.button, textColorHover: color as string } })} />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}