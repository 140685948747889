import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';
import Loading from '../../components/Loading/loading';
import "../../components/customScroll.styles.scss";
import { Link, useLocation } from 'react-router-dom';
import { IoIosArrowForward } from "react-icons/io";
import { LuDownload } from "react-icons/lu";
import InputsComponent from './HTMLInputs';
import StylesComponent from './HTMLStyles';
import inputObj from '../../jsons/input.json'
import stylesObj from '../../jsons/styles.json'
import { Inputs } from '../../interfaces/Inputs';
import { Styles } from '../../interfaces/Styles';
import { useUser } from '../../contexts/userContext';
import { TemplateHTML } from '../../interfaces/TemplatesHTML';
import HTMLComponent from './HTMLComponent';
import { clearCache, getCache, putData, setCache } from '../../components/DataHandler';
import Toaster from '../../components/Toaster';
import { IoHelpCircleSharp } from 'react-icons/io5';
import { useDataBases } from '../../contexts/databasesContext';
import { renderToString } from 'react-dom/server';
import { ServerStyleSheet } from 'styled-components';

interface ObjectToUpdate {
    inputs: string,
    styles: string,
    list_id?: string
}

export default function EditHTML() {
    const { user } = useUser();
    const [loading, setLoading] = useState<boolean>(false);
    const [inputOrStyle, setInputOrStyle] = useState<boolean>(true);
    const [inputs, setInputs] = useState<Inputs>(inputObj);
    const [styles, setStyles] = useState<Styles>(stylesObj);
    const [bd, setBd] = useState<string>('');
    const { databases } = useDataBases();
    const location = useLocation();
    const { id, name } = location.state || {};



    const handleUpdate = async () => {
        setLoading(true)
        const idToken = user!.idToken ? user!.idToken : null;

        let updateObj: ObjectToUpdate = {
            inputs: JSON.stringify(inputs),
            styles: JSON.stringify(styles),
        }

        if (bd) {
            updateObj = { ...updateObj, list_id: bd }
        }

        const response = await putData(`/enrollments/${id}`, updateObj, idToken);
        if (response.status === 200 || response.status === 201) {
            setCache(`/enrollments/${id}`, response.data);
            clearCache(`/enrollments/`);
            Toaster.show(`Template ${name} editado com sucesso.`, 'success');
        } else {
            Toaster.show(`Ocorreu um erro ao editar ${name}.`, 'error');
        }
        setLoading(false)

    }
const handleDownload = () => {
    const sheet = new ServerStyleSheet();
    const html = renderToString(sheet.collectStyles(<HTMLComponent styles={styles} inputs={inputs} />));
    const styleTags = sheet.getStyleTags();

    if (!html) {
        Toaster.show('Ocorreu um erro ao fazer download do template.', 'error');
        return;
    }

    if (!bd) {
        Toaster.show('Template tem de ter uma base de dados associada.', 'error');
        return;
    }

    const a = document.createElement('a');
    const file = new Blob([`
        ${styleTags}
        ${html}
        <script>
        document.addEventListener('DOMContentLoaded', function() {
            const button = document.querySelector('button'); // Adjust the selector to match your button
            button.addEventListener('click', function() {
                const inputs = document.querySelectorAll('input');
                const data = {};
                let allFilled = true;
                
                inputs.forEach(input => {
                    if (input.type === 'checkbox') {
                        data[input.name] = input.checked;
                    } else {
                        if (!input.value) {
                            allFilled = false;
                        }
                        data[input.name] = input.value;
                    }
                });
                
                if (!allFilled) {
                    alert('All inputs must be filled before subscribing.');
                    return;
                }

                const jsonData = JSON.stringify(data);
                console.log('Sending data:', jsonData);
                
                fetch('https://api.newsletter.visiond.pt:3002/add/${bd}', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: jsonData,
                })
                .then(response => {
                    console.log('Response status:', response.status);
                    return response.json();
                })
                .then(data => console.log('Success:', data))
                .catch((error) => console.error('Error:', error));
            });
        });
        </script>
    `], { type: 'text/html' });

    a.href = URL.createObjectURL(file);
    a.download = `${name}.html`;
    a.click();
};



    useEffect(() => {
        setLoading(true)
        const storedTemplate = getCache(`/enrollments/${id}`)
        if (storedTemplate) {
            setInputs(JSON.parse(storedTemplate.inputs))
            setStyles(JSON.parse(storedTemplate.styles))
            setBd(databases.find((db) => db.id === storedTemplate.list_id) ? storedTemplate.list_id : '')
        } else {
            const storedTemplates = getCache(`/enrollments/`);
            if (storedTemplates) {
                const html = storedTemplates.data.find((html: TemplateHTML) => html.id === id);
                setCache(`/enrollments/${id}`, html);
                setInputs(JSON.parse(html.inputs))
                setStyles(JSON.parse(html.styles))
                setBd(databases.find((db) => db.id === html.list_id) ? html.list_id : '')
            }
        }
        setLoading(false)
    }, [databases, id]);

    return (
        <Page >

            <div className='h-full min-h-screen'>
                <div className='flex flex-col gap-8'>
                    <div className='flex gap-4 items-center'>
                        <Link to="/builder/inSite" className='cursor-pointer'>Templates</Link>
                        <IoIosArrowForward size={24} title='Template' />
                        <p className='font-bold'>{name}</p>
                    </div>
                    <div className='flex justify-between gap-8 flex-wrap'>
                        <div onClick={handleDownload} className='max-w-56 group relative text-white flex gap-4 cursor-pointer bg-dashBlue rounded-xl p-3'>
                            <p className='text-xl text-center'>Template {name}</p>
                            <LuDownload size={28} title='Download' />
                            <span className='absolute top-12 left-44 scale-0 z-50 group-hover:scale-100 transition-all duration-200 ease-in-out bg-zinc-700 w-60 rounded-xl p-3'>
                                <p className='text-sm'>
                                    Faça download do template HTML para utilizar no seu site.
                                </p>
                            </span>
                        </div>
                        <button onClick={handleUpdate} className='w-32 p-2 bg-dashBlue text-white rounded-full text-lg'>
                            Save
                        </button>
                    </div>
                    <div className='flex gap-4 flex-col'>
                        <div className='group relative'>
                            <div className='flex gap-1 items-center text-zinc-500 cursor-pointer'>
                                <IoHelpCircleSharp size={40} />
                                <p className='text-xl'>Help</p>
                            </div>

                            <span className='absolute top-[36px] scale-0 z-50 group-hover:scale-100 transition-all duration-200 ease-in-out bg-zinc-100 w-60 rounded-2xl p-2'>
                                <p className='text-sm text-zinc-500'>
                                    Poderá editar o template HTML do seu email, alterando os inputs e os estilos.
                                    <br />
                                    <br />
                                    <b className='font-bold text-lg'>Inputs:</b> Altere os valores dos inputs do seu template ou acrescente novos.
                                    <br />
                                    <br />
                                    <b className='font-bold text-lg'>Styles:</b> Altere os estilos do seu template.
                                    <br />
                                    <br />
                                    <b className='font-bold text-lg'>Save:</b> Guarde as alterações feitas no seu template.
                                </p>
                            </span>
                        </div>
                    </div>
                    <div className='flex flex-col gap-2'>
                        <p className='text-xl text-zinc-400'>Base de Dados associada</p>
                        {databases.length > 0 ? <select
                            className="w-72 outline-none px-2 py-2 text-sm border bg-transparent border-gray-300 border-solid rounded-xl"
                            value={bd}
                            onChange={(e) => setBd(e.target.value)}
                        >
                            <option value="" disabled>Select a Database</option>
                            {databases.map((database, index) => (
                                <option key={index} value={database.id} className='break-words'>{database.name}</option>
                            ))}
                        </select>
                            :
                            <div className='flex gap-1'>
                                <p className='text-zinc-500'>Não existem bases de dados ativas.</p>
                                <Link to="/dataBases" className='text-dashBlue'>Crie uma nova</Link>
                            </div>
                        }
                    </div>
                </div>
                <div className='flex  flex-col milequatro:flex-row gap-10 pt-12'>
                    <div className='w-full milequatro:w-[40%]'>
                        <div className="flex w-full py-4">
                            <div className="w-full bg-white mil:w-[20rem]">
                                <div className="relative flex justify-between gap-4 font-bold ">
                                    <div id="this" className={`top-1/2 translate-y-[-50%] w-1/2 z-0 absolute text-xl font-bold rounded-full h-10 bg-dashBlue transition-all duration-500 ${inputOrStyle ? 'left-0' : 'left-1/2'}`}></div>
                                    <button
                                        onClick={() => setInputOrStyle(true)}
                                        className={`z-0 relative w-full px-4 py-2 transition-colors duration-500 ${inputOrStyle ? 'text-white' : 'text-black'}`}
                                    >
                                        Inputs
                                    </button>
                                    <button
                                        onClick={() => setInputOrStyle(false)}
                                        className={`z-0 relative w-full px-4 py-2 transition-colors duration-500 ${!inputOrStyle ? 'text-white' : 'text-black'}`}
                                    >
                                        Styles
                                    </button>
                                </div>
                            </div>
                        </div>
                        {inputs && <div className={`${inputOrStyle ? "opacity-100" : "opacity-0"} transition-all ease-in-out duration-1000`}>
                            {inputOrStyle && <InputsComponent inputs={inputs} setInputs={setInputs} />}
                        </div>}
                        {styles && <div className={`${!inputOrStyle ? "opacity-100" : "opacity-0"} transition-all ease-in-out duration-1000`}>
                            {!inputOrStyle && <StylesComponent styles={styles} setStyles={setStyles} />}
                        </div>}
                    </div>

                    <div className='flex flex-col gap-4 w-full milequatro:w-[60%] '>
                        <div className='flex flex-col gap-4 border-4 border-solid border-black rounded-xl '>
                            <p className='p-4 text-2xl text-center text-dashBlue'>EDITOR HTML</p>
                            {inputs && styles && <div className='w-full  min-h-[40rem] h-full flex justify-center items-center text-3xl'>
                                <HTMLComponent styles={styles} inputs={inputs} />
                            </div>}
                        </div>
                        <p className='text-xl text-center'>Template {name}</p>
                    </div>
                </div>
                <Loading isOpen={loading} />
            </div>
        </Page>
    );
}


