import { Transition } from "react-transition-group";
import { useEffect, useRef } from "react";

const Modal = ({ isOpen, children, handler }: { isOpen: boolean, children: React.ReactNode, handler:  (() => void) | (() => Promise<void>) | null }) => {
    const transitionDuration = 400;

    // Create refs for the elements that are being transitioned
    const backdropRef = useRef<HTMLDivElement>(null);
    const modalRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'auto';
        }
    }, [isOpen]);

    useEffect(() => {
        const handleKeyDown = async (event: { key: string; }) => {
            if (event.key === 'Enter' && isOpen && handler) {
                const result = handler();
                if (result instanceof Promise) {
                    await result;
                }
            }
        };

        document.addEventListener('keydown', handleKeyDown);

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, handler]);

    return (
        <Transition in={isOpen} timeout={transitionDuration} unmountOnExit nodeRef={backdropRef}>
            {(state) => (
                <>
                    <div
                        ref={backdropRef} // Use the ref here
                        className={`fixed inset-0 z-[60] bg-white transition-opacity duration-${transitionDuration} ${state === 'entered' ? 'opacity-70' : 'opacity-0'}`}
                    ></div>
                    <div
                        ref={modalRef} // Use the ref here
                        className={`fixed inset-0 z-[70] flex items-center justify-center overflow-x-hidden overflow-y-auto outline-none focus:outline-none ${state === "entering" ? "scale-0" : "scale-100"
                            }`}
                    >
                        <div className="absolute min-w-[40%] w-full h-full oito:w-auto oito:h-auto">
                            <div
                                className={`relative z-[80] bg-white oito:border-2 oito:border-solid oito:border-zinc-300 w-full h-full oito:rounded-[40px] shadow-lg transition-transform duration-${transitionDuration} ${state === "entering" || state === "exiting" ? "scale-0" : "scale-100"
                                    }`}
                            >
                                {children}
                            </div>
                        </div>
                    </div>
                </>
            )}
        </Transition>
    );
};

export default Modal;