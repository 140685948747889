
import { useEffect, useState } from 'react';
import { useUser } from '../contexts/userContext';

import { useLocation } from 'react-router-dom'; // Step 1: Import useLocation



const ProfileComponent = () => {
    const { user } = useUser();
    let letter = user?.email ? user.email[0].toUpperCase() : '';
    const location = useLocation();
    const imgSrc = user!.image ? user!.image : null;


    return (
    <div className='flex gap-14 justify-end'>
        <div className='flex items-center justify-end'>
            <div className='flex gap-6'>
                <p>{location.pathname !== "/settings" ? "PUBLICADOR:" : "LOGGED AS:"}</p>
                <p className='font-bold text-end'>{user!.email}</p>
            </div>
        </div>
        {imgSrc ? <img
            className="w-10 aspect-square border-2 border-black rounded-full bg-[var(--primary)]"
            src={imgSrc}
            alt="profileImage"
        /> :
        <div className='relative bg-black rounded-full w-8 h-8 flex justify-center items-center'>
            <p className='flex items-center justify-center absolute text-xl text-white top-[50%] translate-y-[-42%]'>{letter}</p>
        </div>}
    </div>)
};


const Header = () => {
    
    const HeaderTop = () => {
        return (
            <div className='flex-col bg-zinc-100 hidden oito:flex h-20 w-full'>
                <div className='w-full flex justify-end seis:px-[4vw] px-[8vw] items-center h-[6rem]'>
                    <div className="flex items-center gap-8">
                        <div >
                            <ProfileComponent/>
                        </div>
                    </div>
                </div>
            </div>

        )
    }



    return (
        <HeaderTop />
    );
}

export default Header;
