import React, { useEffect, useCallback, useState } from 'react';
import Page from '../../components/Page';
import Loading from '../../components/Loading/loading';
import "../../components/customScroll.styles.scss";
import { Link, useNavigate } from 'react-router-dom';
import { FaDesktop } from 'react-icons/fa';
import { IoIosClose, IoMdArrowRoundBack } from "react-icons/io";
import { TemplateHTML } from '../../interfaces/TemplatesHTML';
import inputObj from '../../jsons/input.json'
import stylesObj from '../../jsons/styles.json'
import { useUser } from '../../contexts/userContext';
import Modal from '../../components/Modal';
import { IoHelpCircleSharp } from 'react-icons/io5';
import HTMLComponent from './HTMLComponent';
import { clearCache, fetchData, postData } from '../../components/DataHandler';
import Toaster from '../../components/Toaster';



export default function Builder() {
    const { user } = useUser();
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const [selectedHtml, setSelectedHtml] = useState<TemplateHTML>();
    const [htmls, setHtmls] = useState<TemplateHTML[]>([]);
    const [name, setName] = useState<string>('');
    const [showModal, setShowModal] = useState<boolean>(false);
    const lang = navigator.language || 'en';

    const handleTemplatesGetter = useCallback(async () => {
        setLoading(true);
        const idToken = user!.idToken ? user!.idToken : null;
        const response = await fetchData('/enrollments/', idToken);
        if (response.status === 200 || response.status === 201) {
            const htmlTemplates = response.data;
            setHtmls(htmlTemplates)
            if (htmlTemplates.length > 0) {
                setSelectedHtml(htmlTemplates[0])
            }
        }
        setLoading(false)
    }, [user]);

    const handleAddTemplate = async () => {
        const idToken = user!.idToken ? user!.idToken : null;

        if (!name) {
            Toaster.show('Por favor, insira um nome para o template.', 'error');
            return;
        }

        setLoading(true);
        const html = {
            name: name,
            inputs: JSON.stringify(inputObj),  //default values of creation
            styles: JSON.stringify(stylesObj), //default values of creation
        }

        const response = await postData(`/enrollments/`, html, idToken);
        clearCache('/enrollments/');

        if (response.status === 200 || response.status === 201) {
            const newTemplate = response.data;
            const newHtmls = [...htmls, newTemplate];
            setHtmls(newHtmls);
            setSelectedHtml(newTemplate);
            Toaster.show(`Template ${name} adicionado com sucesso.`, 'success');
        } else {
            Toaster.show('Não foi possível adicionar o template. Tente novamente.', 'error');
            console.error('Template add failed');
        }
        setLoading(false);
        setShowModal(false);
    }


    const toggleCancel = () => {
        setName('');
        setShowModal(false);
    }

    useEffect(() => {
        handleTemplatesGetter()
    }, [handleTemplatesGetter]);

    return (
        <Page >

            <div className='h-full min-h-screen'>
                <div className='flex flex-col gap-8'>
                    <button onClick={() => navigate("/builder")} className='flex gap-4 items-center cursor-pointer'>
                        <IoMdArrowRoundBack size={32} title='Go Back' />
                        <p>Go to Builder</p>
                    </button>
                    <div className='flex justify-between gap-10 items-center'>
                        <p className=' text-xl'>Builder In-Site</p>
                        <button onClick={() => setShowModal(true)} className='w-32 p-2 bg-dashBlue text-white rounded-full text-lg'>
                            Add +
                        </button>
                    </div>
                </div>
                <div className='flex flex-col milecinco:flex-row gap-10 pt-12'>
                    <div className='flex gap-8 flex-col'>
                        <div className='flex flex-col gap-10'>
                            {/* <div className='flex gap-8 items-center'> */}
                            {/* <button className='w-52 p-4 bg-dashBlue text-white rounded-full text-lg'>
                                    <div className='flex gap-4 justify-center'>
                                        <p>New/Import</p>
                                        <LuImport size={28} title='New/Import' />
                                    </div>
                                </button> */}
                            <Link className='flex gap-4 items-center' to={`${process.env.REACT_APP_MODULES_URL}marketplace`}>
                                <FaDesktop size={32} title="MarketPlace" />
                                <p className='text-lg'>MarketPlace</p>

                            </Link>
                            {/* </div> */}
                            <div className='flex flex-col mileseis:flex-row gap-8'>
                                <div className='flex flex-col gap-8'>
                                    <div className='flex gap-2'>
                                        <p className='text-xl'>Input</p>
                                        <p className='text-xl text-blue-400'>In-site</p>
                                    </div>
                                    <div className='scroll overflow-y-auto max-h-[17rem] pr-8 w-80'>
                                        <div className='flex flex-col gap-4'>
                                            {htmls.length > 0 ? <div className='flex flex-col gap-4'>
                                                {htmls.map((html, index) => (
                                                    <div key={index}
                                                        onDoubleClick={() => navigate(html.name, { state: { id: html.id, name: html.name } })}
                                                        onClick={() => setSelectedHtml(html)}
                                                        className={`select-none cursor-pointer items-center flex gap-4 px-4 h-16 border-4 border-solid border-black ${selectedHtml?.id === html.id ? "bg-dashBlue text-white" : "bg-zinc-100 text-black"} rounded-3xl w-72 transition-all duration-500 ease-in-out`}>
                                                        <p>Template {html.name}</p>
                                                        {selectedHtml?.id === html.id && <button onClick={() => navigate(html.name, { state: { id: html.id, name: html.name } })} className='bg-orange-400 hover:bg-orange-500 text-white rounded-full p-2 w-20  transition-all duration-500 ease-in-out'>Edit</button>}
                                                    </div>
                                                ))}
                                            </div> : <p className='text-xl text-center text-zinc-500'>No Templates to display</p>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className='flex flex-col gap-4 w-full'>
                        <div className='flex flex-col gap-4 border-4 border-solid border-black rounded-xl '>
                            <p className='p-4 text-2xl text-center text-dashBlue'>EDITOR HTML</p>
                            <div className='w-full min-h-[40rem] overflow-auto h-full flex justify-center items-center text-3xl'>
                                {(selectedHtml) ? <HTMLComponent styles={JSON.parse(selectedHtml.styles)} inputs={JSON.parse(selectedHtml.inputs)} />
                                    : <p className='text-xl text-center text-zinc-500'>No Template selected. Try creating one.</p>}
                            </div>
                        </div>
                        <p className='text-xl text-center'>Template {selectedHtml?.name}</p>
                    </div>
                </div>
                <Loading isOpen={loading} />
            </div>
            <Modal isOpen={showModal} handler={handleAddTemplate}>
                <div className='p-8'>
                    <div className='flex justify-between'>
                        <div>
                            <p className='text-xl'>{lang === "pt" ? "Adicionar Template" : "Add Template"}</p>
                        </div>
                        <IoIosClose onClick={() => setShowModal(false)} className='cursor-pointer' size={40} title={lang === "pt" ? "Fechar" : "Close"} />
                    </div>
                    <div className='py-6'>
                        <div className='p-4 bg-zinc-100 text-zinc-400 rounded-xl flex items-center gap-2'>
                            <IoHelpCircleSharp size={24} />
                            <p className='text-sm'>{lang === "pt" ?
                                "Insira o nome que deseja atribuir ao template, que deve ser único." :
                                "Enter the name you want to assign to the temaplate, which must be unique."}
                            </p>
                        </div>
                        <div className='py-8 flex justify-around items-center'>
                            <div className='flex flex-col gap-8'>
                                <div className='flex flex-col gap-2'>
                                    <label className='text-sm'>{lang === "pt" ? "Nome do Template" : "Template Name"}</label>
                                    <input
                                        className="w-72 outline-none px-4 py-2 text-sm border border-gray-300 border-solid rounded-full"
                                        type="text"
                                        placeholder='| Name'
                                        onChange={(e) => setName(e.target.value)}
                                        value={name}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-8 justify-end'>
                        <button onClick={toggleCancel} className="bg-zinc-200 text-zinc-500 py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Cancelar" : "Cancel"}</span>
                        </button>
                        <button onClick={handleAddTemplate} className="bg-dashBlue text-white py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Adicionar" : "Add"}</span>
                        </button>

                    </div>
                </div>
            </Modal>
        </Page>

    );
}

