// src/contexts/DataBaseContext.tsx
import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { DataBase } from '../interfaces/DataBase';
import { fetchData, setCache } from '../components/DataHandler';
import { useUser } from './userContext';

interface DataBaseContextProps {
    databases: DataBase[];
    setDataBases: React.Dispatch<React.SetStateAction<DataBase[]>>;
    getDataBases: () => Promise<void>;
}

const DataBaseContext = createContext<DataBaseContextProps | undefined>(undefined);

export const DataBaseProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
    const { user } = useUser();
    const [databases, setDataBases] = useState<DataBase[]>([]);

    const getDataBases = useCallback(async () => {
        if (user) {
            const idToken = user!.idToken ? user!.idToken : null;
            const response = await fetchData('/lists/', idToken);
            if (response.status === 200 || response.status === 201) {
                setDataBases(response.data);
            }
        }
    }, [user]);

    useEffect(() => {
        getDataBases();
    }, [getDataBases]);


    useEffect(() => {
        setCache('/lists/', databases);
    }, [databases]);

    return (
        <DataBaseContext.Provider value={{ databases, setDataBases, getDataBases }}>
            {children}
        </DataBaseContext.Provider>
    );
};

export const useDataBases = (): DataBaseContextProps => {
    const context = useContext(DataBaseContext);
    if (!context) {
        throw new Error('useDataBases must be used within a DataBaseProvider');
    }
    return context;
};