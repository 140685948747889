import React, { useEffect, useState } from 'react';
import Page from '../../components/Page';
import Loading from '../../components/Loading/loading';
import "../../components/customScroll.styles.scss";
import type { DataBase } from '../../interfaces/DataBase';
import { IoIosClose, IoIosSearch } from 'react-icons/io';
import { IoHelpCircleSharp } from 'react-icons/io5';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../../contexts/userContext';
import Wheel from '@uiw/react-color-wheel';
import { hsvaToHex } from '@uiw/color-convert';
import Modal from '../../components/Modal';
import stateBgColor from '../../components/StatusHandler';
import Toaster from '../../components/Toaster';
import { postData } from '../../components/DataHandler';
import { HSVA } from '../../interfaces/HSVA';
import { useDataBases } from '../../contexts/databasesContext';

type DataBaseState = {
    newDatabaseName: string;
    hsva: HSVA;
}

export default function DataBases() {
    const { user } = useUser()
    const lang = navigator.language || 'en';
    const [loading, setLoading] = useState<boolean>(false);
    const navigate = useNavigate();
    const { databases, setDataBases } = useDataBases();
    const [filtereddatabases, setfiltereddatabases] = useState<DataBase[]>([]);
    const [search, setSearch] = useState<string>("");
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [databaseState, setDatabaseState] = useState<DataBaseState>({
        newDatabaseName: "",
        hsva: { h: 214, s: 43, v: 90, a: 1 }
    });

    const updateDatabaseState = (key: any, value: any) => {
        setDatabaseState(prevState => ({
            ...prevState,
            [key]: value
        }));
    };


    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };



    const BDCard = ({ db }: { db: DataBase }) => {


        const stateColor = stateBgColor(db.state);

        return (
            <div onClick={() => navigate(db.name, { state: { id: db.id, name: db.name, color: db.color, number: db.number, state: db.state } })} className={`border-2 border-zinc-300 rounded-2xl p-4 cursor-pointer flex flex-col gap-4`}>
                <div className="flex items-center justify-between">
                    <span className="text-zinc-500 text-xl">Database</span>
                    <div style={{ backgroundColor: `#${db.color}` }} className="w-8 h-8 text-white rounded-full flex items-center justify-center">{db.number}</div>
                </div>
                <span className="text-lg break-words">{db.name}</span>
                <div className="flex items-center gap-2">
                    <div className={`w-3 h-3 ${stateColor.color} rounded-full`}></div>
                    <span className="text-black">{stateColor.text}</span>
                </div>
            </div>
        )
    }

    const handleDBCreate = async () => {

        const idToken = user!.idToken ? user!.idToken : null;

        if (databaseState.newDatabaseName === '') {
            Toaster.show('Preencha o nome.', 'error');
            return;
        }

        setLoading(true);
        const dbObj = {
            name: databaseState.newDatabaseName,
            color: hsvaToHex(databaseState.hsva).substring(1),
            state: "active",
        }
        const response = await postData("/lists/", dbObj, idToken);
        if (response.status === 200 || response.status === 201) {
            const newDbs = [...databases, { name: databaseState.newDatabaseName, color: hsvaToHex(databaseState.hsva).substring(1), id: response.data.id, number: response.data.number, state: response.data.state, contacts: [] }];
            setDataBases(newDbs)
            Toaster.show(`Base de Dados ${databaseState.newDatabaseName} criada com sucesso.`, 'success');
        } else {
            Toaster.show('Não foi possível criar a base de dados. Tente novamente.', 'error');
            console.error('Create database failed');
        }

        setLoading(false);
        updateDatabaseState('newDatabaseName', "");
        updateDatabaseState('hsva', { h: 214, s: 43, v: 90, a: 1 });
        setShowModal(false);
    }





    useEffect(() => {

        if (databases.length > 0) {
            let activedbs: DataBase[] = [];
            let inactiveDraftsdatabases: DataBase[] = [];

            databases.forEach(db => {
                if (db.state.toLocaleLowerCase() === "active") {
                    activedbs.push(db);
                } else {
                    inactiveDraftsdatabases.push(db);
                }
            });
            setfiltereddatabases(activedbs);
        }
    }, [databases]);


    useEffect(() => {
        if (search === '') {
            setfiltereddatabases(databases);
            return;
        }
        const searchNumber = Number(search);
        const isSearchNumber = !isNaN(searchNumber);
        const filtered = databases.filter((db) =>
            isSearchNumber ? db.number === searchNumber :
                db.name.toLowerCase().includes(search.toLowerCase())
        );
        setfiltereddatabases(filtered);

    }, [search, databases]);



    const toggleCancel = () => {
        updateDatabaseState('newDatabaseName', "");
        updateDatabaseState('hsva', { h: 214, s: 43, v: 90, a: 1 });
        setShowModal(false);
    }

    return (
        <Page >
            <div className='h-screen'>
                <div className='flex'>
                    <h2 className='text-xl'>{lang === "pt" ? "Base Dados ativas" : "Active DataBases"}</h2>
                </div>
                <div className="py-6">
                    <div className="flex gap-2 pb-6">
                        {databases.filter(db => db.state.toLocaleLowerCase() === "active").map((db, index) => (
                            <div key={index} style={{ backgroundColor: `#${db.color}` }} className={`w-8 h-8 text-white rounded-full flex items-center justify-center`}>{db.number}</div>
                        ))}
                    </div>
                    <div className="flex items-center gap-2 justify-between pb-6">
                    <div className='flex gap-2 w-full items-center'>
                            <div className='group relative'>
                                <IoHelpCircleSharp className='text-zinc-500' size={40} />
                                <span className='absolute top-12 scale-0 group-hover:scale-100 transition-all duration-200 ease-in-out bg-zinc-100 w-60 aspect-square rounded-2xl p-2'>
                                    <p className='text-lg text-black'>Ajuda</p>
                                    <p className='text-sm text-zinc-500'>
                                        Poderá pesquisar por nome ou número de equipa.
                                        Equipas inativas são marcadas a vermelho e ativas a verde.
                                        As equipas ativas são as que têm menos membros do que o máximo permitido.
                                        Poderá adicionar uma nova equipa clicando no botão no canto superior direito e posteriormente adicionar novos membros.
                                    </p>
                                </span>
                            </div>
                            <div className="w-1/2">
                                <div className=" bg-gray-200 rounded-full shadow flex px-2 py-1 w-fit">
                                    <input type="text" name="search" className={`${showSearch ? "w-60 px-4" : "w-0"} transition-all duration-300 ease-in-out dark:text-gray-800 outline-none bg-transparent`} onChange={handleSearchChange} value={search} />
                                    <button type={showSearch ? "submit" : "button"}>
                                        <div>
                                            <IoIosSearch onClick={() => setShowSearch(!showSearch)} className='text-zinc-500' size={36} title="Pesquisar" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4'>
                            <button onClick={() => setShowModal(true)} className="bg-dashBlue w-60 text-white py-2 px-4 rounded-full flex items-center justify-center gap-2">
                                <span>+</span>
                                <span>{lang === "pt" ? "Nova Base de Dados" : "New DataBase"}</span>
                            </button>
                        </div>
                    </div>
                    <div className='scroll overflow-auto max-h-[45rem] w-full pr-2'>

                        {filtereddatabases.length > 0 ?
                            <div className='py-8'>
                                <div className="grid cinco:grid-cols-2 miledois:grid-cols-3 gap-10">
                                    {filtereddatabases.map((database, index) => (
                                        <BDCard key={index} db={database} />
                                    ))}
                                </div>
                            </div>
                            :
                            <div className="flex justify-center items-center h-96">
                                <p className='text-xl text-zinc-500'>{lang === "pt" ? "Sem Bases de Dados ativas, por favor crie uma nova e adicione utilizadores" : "No active data bases, please create a new one and add users"}</p>
                            </div>
                        }


                    </div>

                </div>
            </div>
            <Modal isOpen={showModal} handler={handleDBCreate}>
                <div className='p-8'>
                    <div className='flex justify-between'>
                        <div>
                            <p className='text-xl'>{lang === "pt" ? "Adicionar Base de Dados" : "Add DataBase"}</p>
                        </div>
                        <IoIosClose onClick={() => setShowModal(false)} className='cursor-pointer' size={40} title={lang === "pt" ? "Fechar" : "Close"} />
                    </div>
                    <div className='py-6'>
                        <div className='p-4 bg-zinc-100 text-zinc-400 rounded-xl flex items-center gap-2'>
                            <IoHelpCircleSharp size={24} />
                            <p className='text-sm'>{lang === "pt" ?
                                "Insira o nome que deseja atribuir à nova campanha que terá de ser único." :
                                "Enter the name you want to assign to the new campaign, which must be unique."}
                            </p>
                        </div>
                        <div className='py-8 flex justify-around items-center'>
                            <div className='flex flex-col gap-8'>
                                <div className='flex flex-col gap-2'>
                                    <label className='text-sm'>{lang === "pt" ? "Nome da Base de Dados" : "DataBase Name"}</label>
                                    <input
                                        className="w-72 outline-none px-4 py-2 text-sm border border-gray-300 border-solid rounded-full"
                                        type="text"
                                        placeholder='| Name'
                                        onChange={(e) => updateDatabaseState('newDatabaseName', e.target.value)}
                                        value={databaseState.newDatabaseName}
                                    />
                                </div>
                            </div>
                            <div className='flex flex-col gap-6'>
                                <Wheel color={databaseState.hsva} onChange={(color) => updateDatabaseState('hsva', { ...databaseState.hsva, ...color.hsva })} />
                                <div className='flex justify-center'>
                                    <div className='flex gap-2 items-center'>
                                        <p className='text-xl text-zinc-500'>{lang === "pt" ? "Equipa" : "Team"}</p>
                                        <div className='rounded-full h-6 w-6' style={{ background: hsvaToHex(databaseState.hsva) }}></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-8 justify-end'>
                        <button onClick={toggleCancel} className="bg-zinc-200 text-zinc-500 py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Cancelar" : "Cancel"}</span>
                        </button>
                        <button onClick={handleDBCreate} className="bg-dashBlue text-white py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Adicionar" : "Add"}</span>
                        </button>

                    </div>
                </div>
            </Modal>
            <Loading isOpen={loading} />
        </Page>

    );
}

