import React, { useCallback, useEffect, useState } from 'react';
import Page from '../../components/Page';
import Loading from '../../components/Loading/loading';
import "../../components/customScroll.styles.scss";
import type { DataBase } from '../../interfaces/DataBase';
import { IoIosClose, IoMdArrowRoundBack } from 'react-icons/io';
import { IoHelpCircleSharp } from 'react-icons/io5';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import "../../components/Toggle/toggle.styles.scss";
import { BsDatabase } from 'react-icons/bs';
import { useUser } from '../../contexts/userContext';
import { Campaign } from '../../interfaces/Campaign';
import Modal from '../../components/Modal';
import { Contacts } from '../../interfaces/Contacts';
import { CampaignHtmlTemplate } from '../../interfaces/CampaignHtmlTemplate';
import { CampaignUpdate } from '../../interfaces/CampaignUpdate';
import stateBgColor from '../../components/StatusHandler';
import { clearCache, fetchData, putData } from '../../components/DataHandler';
import Toaster from '../../components/Toaster';
import { useDataBases } from '../../contexts/databasesContext';
import formatDate from '../../components/DateFormatter';
import { EmailSender } from '../../interfaces/Senders';


export default function CampaignComponent() {
    const { user } = useUser();
    const lang = navigator.language || 'en';
    const location = useLocation();
    const { id, name, color, number, state } = location.state || {};
    const [campaignstate, setCampaignstate] = useState<string>(state === "active" ? "active" : "inactive");
    const [loading, setLoading] = useState<boolean>(false);
    const [checked, setChecked] = useState<Number[]>([]);
    const [campaign, setCampaign] = useState<Campaign>();
    const [users, setUsers] = useState<Contacts[]>([]);
    const [email, setEmail] = useState<string>("");
    const [showModal, setShowModal] = useState<boolean>(false);
    const { databases } = useDataBases();
    const [activedatabases, setdatabasesactive] = useState<DataBase[]>([]);
    const [dbsToAdd, setDbsToAdd] = useState<string[]>([]);
    const [creationDate, setCreationDate] = useState<string>("");
    const [updateDate, setUpdateDate] = useState<string>("");
    const [selectedHtml, setSelectedHtml] = useState<string>("");
    const [campaignHtmls, setCampaignHtmls] = useState<CampaignHtmlTemplate[]>([]);
    const [selectedHtmlCampaign, setSelectedHtmlCampaign] = useState<string>("");
    const [emailSubject, setEmailSubject] = useState<string>("");
    const [date, setDate] = useState<string>("");
    const navigate = useNavigate();
    const [senders, setSenders] = useState<EmailSender[]>([]);




    const handleSelect = (index: number): void => {
        const newChecked = [...checked];
        if (newChecked.includes(index)) {
            newChecked.splice(newChecked.indexOf(index), 1);
        } else {
            newChecked.push(index);
        }
        setChecked(newChecked);
    }

    const handleSelectDbs = (id: string): void => {
        const newDbsToAdd = [...dbsToAdd];
        if (id) {
            if (dbsToAdd.includes(id)) {
                newDbsToAdd.splice(newDbsToAdd.indexOf(id), 1);
            } else {
                newDbsToAdd.push(id);
            }
            setDbsToAdd(newDbsToAdd);
        }
    }


    const handlestateChange = (state: string) => {
        if ('active' === state)
            setCampaignstate('inactive')
        else
            setCampaignstate('active')
    }


    const getCampaign = useCallback(async () => {
        const idToken = user!.idToken ? user!.idToken : null;
        setLoading(true);
        clearCache(`/campaigns/${id}`);
        const response = await fetchData(`/campaigns/${id}`, idToken);
        if (response.status === 200 || response.status === 201) {
            const campainObj = response.data;
            setCampaign({ ...campainObj.campaign, lists: campainObj.lists });
            setEmail(campainObj.campaign.emailsender)
            setEmailSubject(campainObj.campaign.subject);
            setDate(campainObj.campaign.scheduledat);
            setSelectedHtml(campainObj.campaign.enrollment_id);
            setSelectedHtmlCampaign(campainObj.campaign.template_id);
            const newUsers = campainObj.users ? campainObj.users : [];
            setUsers(newUsers);
            const dbs = campainObj.lists.map((db: { id: any; }) => db.id);
            setDbsToAdd(dbs);
        }
        setLoading(false)
    }, [user, id]);



    const updateCampaign = async () => {

        const idToken = user!.idToken ? user!.idToken : null;


        setLoading(true);
        let campaignToUpdate: CampaignUpdate = {
            state: campaignstate
        };
        if (dbsToAdd.length > 0) {
            campaignToUpdate.lists = dbsToAdd;
        }
        if (selectedHtml !== "") {
            campaignToUpdate.enrollment_id = selectedHtml;
        }
        if (selectedHtmlCampaign !== "") {
            campaignToUpdate.template_id = selectedHtmlCampaign;
        }
        if (email !== "") {
            campaignToUpdate.emailsender = email;
        }
        if (emailSubject !== "") {
            campaignToUpdate.subject = emailSubject;
        }
        if (date !== "") {
            campaignToUpdate.scheduledat = date;
        }

        const response = await putData(`/campaigns/${id}`, campaignToUpdate, idToken);

        if (response.status === 200 || response.status === 201) {
            clearCache(`/campaigns/${id}`);
            getCampaign();
            Toaster.show(`Campanha ${name} updated com sucesso.`, 'success');
        } else {
            Toaster.show('Não foi possível editar a campanha. Tente novamente.', 'error');
        }
        setDbsToAdd([]);
        setLoading(false);
    }





    const toggleCancel = () => {
        setShowModal(false);
        setDbsToAdd([]);
    }

    const addDbs = () => {
        updateCampaign();
        setShowModal(false);
        // Toaster.show(lang === "pt" ? "Salve as suas alteraçoes para adicionar as bases de dados" : "Save your changes to add the databases", 'success');
    }

    const removeDb = (id: string): void => {
        const newDbsToAdd = [...dbsToAdd];
        if (id) {
            const index = newDbsToAdd.indexOf(id);
            if (index > -1) {
                newDbsToAdd.splice(index, 1);
            }
            setDbsToAdd(newDbsToAdd);
        }

        Toaster.show(lang === "pt" ? "Salve as suas alteraçoes para remover a base de dados" : "Save your changes to remove the database", 'success');
    }

    const handleCampaignTemplatesGetter = useCallback(async () => {
        setLoading(true);
        const idToken = user!.idToken ? user!.idToken : null;
        const response = await fetchData('/templates', idToken);
        if (response.status === 200 || response.status === 201) {
            setCampaignHtmls(response.data);
        }
        setLoading(false);
    }, [user]);


    const handleSendersGetter = useCallback(async () => {
        setLoading(true);
        const idToken = user ? user!.idToken : null;
        const response = await fetchData("/senders/", idToken);
        if (response.status === 200 || response.status === 201) {
            const senders = response.data;
            setSenders(senders);
        } else {
            Toaster.show('Não foi possível obter senders.', 'error');
            console.error('Create sender failed');
        }
        setLoading(false);
    }, [user]);

    const handleSelectedHtmlCampaign = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedHtmlCampaign(event.target.value);
    };

    const getSelectedHtmlContent = () => {
        const selectedTemplate = campaignHtmls.find(html => html.id === selectedHtmlCampaign);
        return selectedTemplate ? selectedTemplate.html : '';
    }

    useEffect(() => {
        getCampaign();
    }, [getCampaign]);


    useEffect(() => {
        if (showModal) {
            const activedbs = databases.filter(db => db.state === "active");
            setdatabasesactive(activedbs);
        }
    }, [databases, showModal]);

    useEffect(() => {
        if (campaign) {
            setCreationDate(campaign.createdat);
            setUpdateDate(campaign.updatedat);
        }
    }, [campaign]);


    useEffect(() => {
        handleCampaignTemplatesGetter()
    }, [handleCampaignTemplatesGetter]);


    useEffect(() => {
        handleSendersGetter()
    }, [handleSendersGetter]);

    

    return (
        <Page >

            <div className='h-full'>
                <div className='flex flex-col gap-8'>
                    <button onClick={() => navigate(-1)} className='flex gap-4 items-center cursor-pointer'>
                        <IoMdArrowRoundBack size={32} title='Go Back' />
                        <p>Go Back</p>
                    </button>
                </div>
                <div className='flex flex-col gap-6'>
                    <div className='flex justify-between gap-10 items-center'>
                        <div className='flex gap-4 items-center pt-10'>
                            <h2 className='text-xl text-zinc-500'>{lang === "pt" ? "Campanha" : "Campaign"}</h2>
                            <div style={{ backgroundColor: `#${color}` }} className="w-8 h-8 text-white rounded-full flex items-center justify-center">{number}</div>
                        </div>
                        <button onClick={updateCampaign} className='w-32 p-2 bg-dashBlue text-white rounded-full text-lg'>
                            Save
                        </button>
                    </div>
                    <div className='flex gap-10 justify-between'>
                        <div className='flex flex-col gap-2'>
                            <p className='text-lg text-zinc-400'>{lang === "pt" ? "Nome" : "Name"}</p>
                            <p className='text-lg'>{name}</p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className='text-lg text-zinc-400 text-end'>{lang === "pt" ? "Estado" : "state"}</p>
                            <div className='flex gap-4'>
                                <p>{stateBgColor(campaignstate).text}</p>
                                <Toggle
                                    defaultChecked={campaignstate === "active"}
                                    onChange={() => handlestateChange(campaignstate)} />
                            </div>
                        </div>
                    </div>
                    <div className='flex gap-10'>
                        <div className='flex flex-col gap-6'>
                            <div className='flex flex-col gap-2'>
                                <p className='text-lg text-zinc-400'>{lang === "pt" ? "Email de envio" : "Sender Email"}</p>
                                <select
                                    className="w-80 outline-none px-4 py-2 text-sm border bg-transparent border-gray-300 border-solid rounded-full"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                >
                                    <option value="" disabled>Select a provider</option>
                                    {senders.map((sender, index) => (
                                        <option key={index} value={sender.sender_email}>{sender.sender_email}</option>
                                    ))}
                                </select>
                            </div>
                            <div className='flex flex-col gap-2'>
                                <p className='text-lg text-zinc-400'>{lang === "pt" ? "Assunto do email" : "Email Subject"}</p>
                                <textarea
                                    className="w-80 outline-none px-4 py-2 text-sm border border-gray-300 border-solid rounded-lg"
                                    placeholder='| Subject'
                                    onChange={(e) => setEmailSubject(e.target.value)}
                                    value={emailSubject || ""}
                                />
                            </div>
                        </div>
                        <div className='flex'>
                            <div className='flex flex-col gap-2'>
                                <p className='text-lg text-zinc-400'>{lang === "pt" ? "Agendado para" : "Scheduled for"}</p>
                                <input
                                    className="w-80 outline-none px-4 py-2 text-sm border border-gray-300 border-solid rounded-full"
                                    type="datetime-local"
                                    value={date !== "" ? new Date(date).toISOString().slice(0, 16) : date}
                                    onChange={(e) => setDate(e.target.value)}
                                />
                            </div>

                        </div>
                    </div>
                </div>
                <div className='grid mil:grid-cols-2 gap-10 milequatro:grid-cols-3 milequatro:gap-8 py-10'>
                    <div className='flex flex-col gap-6 justify-around'>
                        <div>
                            <p className='text-lg text-zinc-400'>{lang === "pt" ? "Data de Criação" : "Creation Date"}</p>
                            <p className='text-lg text-zinc-400'>{formatDate(creationDate)}</p>
                        </div>
                        <div>
                            <p className='text-lg text-zinc-400'>{lang === "pt" ? "Data de Atualização" : "Update Date"}</p>
                            <p className='text-lg text-zinc-400'>{formatDate(updateDate)}</p>
                        </div>
                    </div>
                    <div className='flex flex-col gap-4'>
                        <p className='text-lg text-zinc-400'>{lang === "pt" ? "Base de Dados" : "Data Base"}</p>
                        <div className='flex gap-4 items-center '>
                            <div className='flex gap-2  items-center '>
                                <BsDatabase size={28} title="Data Base" />
                                <div className="text-sm rounded-2xl">
                                    {(campaign?.lists && Array.isArray(campaign.lists)) && campaign.lists.map(item => item.number).join(", ")}
                                </div>
                            </div>
                            <button onClick={() => setShowModal(true)} className='text-dashBlue'>
                                + Add
                            </button>
                        </div>
                        {(campaign?.lists && campaign?.lists !== null) && <div className='scroll overflow-y-auto max-h-[15rem] pr-2 w-full'>
                            <div className='flex flex-col gap-4'>
                                {campaign?.lists.map((db, index) => (
                                    <div key={index} className={`cursor-pointer items-center flex gap-4 px-4 h-16 border-4 border-solid border-black rounded-3xl w-full transition-all duration-500 ease-in-out`}>
                                        <p>Data Base {db.name}</p>
                                        <button onClick={() => navigate(`/dataBases/${db.name}`, { state: { id: db.id, name: db.name, color: db.color, number: db.number, state: db.state } })} className='bg-orange-400 hover:bg-orange-500 text-white rounded-full p-2 w-20  transition-all duration-500 ease-in-out'>Edit</button>
                                        <button onClick={() => removeDb(db.id)} className='bg-red-400 hover:bg-red-500 text-white rounded-full p-2 w-20  transition-all duration-500 ease-in-out'>Delete</button>
                                    </div>
                                ))}
                            </div>
                        </div>}
                    </div>
                    <div className='flex flex-col gap-4 items-center'>
                        <p className='text-lg text-zinc-400'>Campaign Template (HTML)</p>
                        <div>
                            {campaignHtmls.length > 0 ?
                                <select
                                    className="w-72 outline-none px-4 py-2 text-sm border bg-transparent border-gray-300 border-solid rounded-lg"
                                    value={selectedHtmlCampaign}
                                    onChange={handleSelectedHtmlCampaign}
                                >
                                    <option value="" disabled>Select a template</option>
                                    {campaignHtmls.map((html, index) => (
                                        <option key={index} value={html.id}>{html.name}</option>
                                    ))}
                                </select>
                                :
                                <div className='flex flex-col gap-2'>
                                    <p className='text-zinc-500'>No available templates</p>
                                    <Link className='cursor-pointer text-dashBlue' to="/builder/campaign">Add one +</Link>
                                </div>}
                        </div>
                        <div className='border overflow-hidden border-solid border-zinc-400 rounded-xl w-[50%] h-80'>
                            <div className='scale-[0.5] transform-gpu origin-top-left w-full h-full' dangerouslySetInnerHTML={{ __html: getSelectedHtmlContent() }} />
                        </div>
                        <button onClick={() => navigate(`/builder/campaign`, { state: { selected: selectedHtmlCampaign } })} className='bg-zinc-100 hover:bg-zinc-200 text-black rounded-full px-4 py-2 transition-all duration-500 ease-in-out'>Preview</button>
                    </div>
                </div>
                <div className="py-6">
                    {users.length > 0 ? <div className='scroll overflow-auto h-[30rem] w-full pr-2'>
                        <div className=' w-full'>
                            <table className=' w-full'>
                                <thead >
                                    <tr className='text-sm text-left text-zinc-500 '>
                                        <th className="px-6 py-3">
                                            {lang === "pt" ? "Nome" : "Name"}
                                        </th>
                                        <th className="px-6 py-3  min-w-[8rem]">
                                            E-Mail
                                        </th>
                                        <th className="px-6 py-3 ">
                                            {lang === "pt" ? "Selecionar" : "Select"}
                                        </th>
                                    </tr>
                                </thead>
                                <tbody className="">
                                    {users.map((elem, index) => (
                                        <tr key={index} className=' text-zinc-500  border-t-2 border-zinc-200'>
                                            <td className="px-6 py-10">
                                                <div className="text-sm font-bold text-black rounded-2xl ">{elem.name}</div>
                                            </td>
                                            <td className="px-6 py-10">
                                                <div className="text-sm font-bold text-black rounded-2xl ">{elem.email}</div>
                                            </td>

                                            <td className="px-6 py-10">
                                                <button
                                                    onClick={() => handleSelect(index)}
                                                    className={`w-8 ${checked.includes(index) && "bg-dashBlue"} aspect-square border border-solid border-black rounded-lg flex items-center justify-center text-white select-none`}>
                                                    <span>&#10003;</span>
                                                </button>
                                            </td>
                                        </tr>
                                    ))}

                                </tbody>
                            </table>
                        </div>

                    </div>
                        : <div className='flex justify-center items-center flex-col gap-2'>
                            <p className='text-zinc-500'>{lang === "pt" ? "Não existem utilizadores" : "There are no users"}</p>
                        </div>}
                </div>
            </div>
            <Modal isOpen={showModal} handler={addDbs}>
                <div className='p-8'>
                    <div className='flex justify-between'>
                        <div>
                            <p className='text-xl'>{lang === "pt" ? "Adicionar base de dados à campanha" : "Add data base to the campaign"}</p>
                        </div>
                        <IoIosClose onClick={() => setShowModal(false)} className='cursor-pointer' size={40} title={lang === "pt" ? "Fechar" : "Close"} />
                    </div>
                    <div className='py-6 flex flex-col gap-4'>
                        <div className='p-4 bg-zinc-100 text-zinc-400 rounded-xl flex items-center gap-2'>
                            <IoHelpCircleSharp size={24} />
                            <p className='text-sm'>{lang === "pt" ?
                                "Selecione as bases de dados que pretende adicionar" :
                                "Select the data bases you want to add"}
                            </p>
                        </div>
                        {activedatabases.length > 0 ? <div className='scroll overflow-auto max-h-[45rem] w-full pr-2'>
                            <div className=' w-full'>
                                <table className=' w-full'>
                                    <thead >
                                        <tr className='text-sm text-left text-zinc-500 '>
                                            <th className="px-6 py-3">
                                                {lang === "pt" ? "Nome" : "Name"}
                                            </th>
                                            <th className="px-6 py-3  min-w-[8rem]">
                                                {lang === "pt" ? "Número" : "Number"}
                                            </th>
                                            <th className="px-6 py-3 ">
                                                {lang === "pt" ? "Selecionar" : "Select"}
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody className="">
                                        {activedatabases.map((elem, index) => (
                                            <tr key={index} className=' text-zinc-500  border-t-2 border-zinc-200'>
                                                <td className="px-6 py-10">
                                                    <div className="text-sm font-bold text-black rounded-2xl ">{elem.name}</div>
                                                </td>
                                                <td className="px-6 py-10">
                                                    <div className="text-sm font-bold text-black rounded-2xl ">{elem.number}</div>
                                                </td>
                                                <td className="px-6 py-10">
                                                    <button
                                                        onClick={() => handleSelectDbs(elem.id)}
                                                        className={`w-8 ${dbsToAdd.includes(elem!.id) && "bg-dashBlue"} cursor-pointer aspect-square border border-solid border-black rounded-lg flex items-center justify-center text-white select-none`}>
                                                        <span>&#10003;</span>
                                                    </button>
                                                </td>
                                            </tr>
                                        ))}

                                    </tbody>
                                </table>
                            </div>
                        </div>
                            :
                            <div className='flex justify-center items-center flex-col gap-2'>
                                <p className='text-zinc-500'>{lang === "pt" ? "Não existem bases de dados ativas" : "There are no active data bases"}</p>
                                <Link to="/dataBases" className='text-dashBlue'>{lang === "pt" ? "Adicionar uma base de dados" : "Add a data base"}</Link>
                            </div>}
                    </div>
                    <div className='flex gap-8 justify-end'>
                        <button onClick={toggleCancel} className="bg-zinc-200 text-zinc-500 py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Cancelar" : "Cancel"}</span>
                        </button>
                        <button onClick={addDbs} className="bg-dashBlue text-white py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Adicionar" : "Add"}</span>
                        </button>

                    </div>
                </div>
            </Modal>
            <Loading isOpen={loading} />
        </Page>

    );
}

