import React, { useCallback, useEffect, useRef, useState } from 'react';
import Page from '../../components/Page';
import Loading from '../../components/Loading/loading';
import "../../components/customScroll.styles.scss";
import { IoIosClose, IoIosSearch, IoMdArrowRoundBack } from 'react-icons/io';
import { IoHelpCircleSharp } from 'react-icons/io5';
import { useLocation, useNavigate } from 'react-router-dom';
import { LuImport } from 'react-icons/lu';
import * as XLSX from 'xlsx';
import { useUser } from '../../contexts/userContext';
import { Contacts } from '../../interfaces/Contacts';
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import "../../components/Toggle/toggle.styles.scss";
import Toaster from '../../components/Toaster';
import { clearCache, fetchData, putData } from '../../components/DataHandler';
import { useDataBases } from '../../contexts/databasesContext';
import Modal from '../../components/Modal';
import { FaCheck } from 'react-icons/fa';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';


export default function DataBasePage() {
    const lang = navigator.language || 'en';
    const { user } = useUser();
    const location = useLocation();
    let { id, name, color, number } = location.state || {};
    const [loading, setLoading] = useState<boolean>(false);
    const [showSearch, setShowSearch] = useState<boolean>(false);
    const [search, setSearch] = useState<string>('');
    const [checked, setChecked] = useState<Number[]>([]);
    const [contacts, setcontacts] = useState<Contacts[]>([]);
    const { databases, setDataBases } = useDataBases();
    const [filteredcontacts, setFilteredcontacts] = useState<Contacts[]>([]);
    const [data, setData] = useState<{ name: string, email: string }[]>([]);
    const [emailsToUpdate, setEmailsToUpdate] = useState<string[]>([]);
    const navigate = useNavigate();
    const [showModal, setShowModal] = useState<boolean>(false);
    const fileInputRef = useRef<HTMLInputElement>(null);
    const [selectedMembers, setSelectedMembers] = useState<{ name: string, email: string }[]>([]);
    const [newUserName, setNewUserName] = useState<string>('');
    const [newUserEmail, setNewUserEmail] = useState<string>('');



    const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        const file = e.target.files?.[0];
        if (file) {
            const reader = new FileReader();
            const fileType = file.type;

            reader.onload = (event) => {
                const fileContent = event.target?.result;

                if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileType === 'application/vnd.ms-excel') {
                    // Handle Excel file
                    const workbook = XLSX.read(fileContent, { type: 'binary' });
                    const sheetName = workbook.SheetNames[0];
                    const sheet = workbook.Sheets[sheetName];
                    const jsonData = XLSX.utils.sheet_to_json<{ name: string, email: string }>(sheet, {
                        header: ['name', 'email'],
                    });
                    setData(jsonData);
                    Toaster.show('Ficheiro importado com sucesso. Salve o ficheiro para adicionar utilizadores.', 'success');
                } else if (fileType === 'text/csv') {
                    // Handle CSV file
                    Papa.parse(fileContent as string, {
                        header: true,
                        complete: (results) => {
                            const jsonData = results.data as { name: string, email: string }[];
                            setData(jsonData);
                            Toaster.show('Ficheiro importado com sucesso. Salve o ficheiro para adicionar utilizadores.', 'success');
                        },
                    });
                } else {
                    Toaster.show('Tipo de ficheiro não suportado.', 'error');
                }
            };

            if (fileType === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' || fileType === 'application/vnd.ms-excel') {
                reader.readAsBinaryString(file);
            } else if (fileType === 'text/csv') {
                reader.readAsText(file);
            }

            if (fileInputRef.current) {
                fileInputRef.current.value = ''; // Reset the input value
            }
        }
    };


    const handleButtonClick = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click();
        }
    };



    const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSearch(event.target.value);
    };


    const handleSelect = (index: number, email: string): void => {
        const newChecked = [...checked];
        const newEmailsToUpdate = [...emailsToUpdate];
        if (newChecked.includes(index)) {
            newChecked.splice(newChecked.indexOf(index), 1);
        } else {
            newChecked.push(index);
        }
        if (newEmailsToUpdate.includes(email)) {
            newEmailsToUpdate.splice(newEmailsToUpdate.indexOf(email), 1);
        } else {
            newEmailsToUpdate.push(email);
        }
        setChecked(newChecked);
        setEmailsToUpdate(newEmailsToUpdate);
    }


    const getDB = useCallback(async () => {
        setLoading(true);
        const idToken = user!.idToken ? user!.idToken : null;
        const response = await fetchData(`/lists/${id}`, idToken);
        if (response.status === 200 || response.status === 201) {
            setcontacts(JSON.parse(response.data.contacts));
        }
        setLoading(false)
    }, [id, user]);



    const updateDb = async () => {

        const idToken = user!.idToken ? user!.idToken : null;
        if (data.length === 0 && emailsToUpdate.length === 0 && newUserEmail === '' && newUserName === '') {
            Toaster.show('Não foram feitas alterações.', 'error');
            return;
        }
        setLoading(true);

        let emailsToAdd = selectedMembers;

        if(newUserEmail !== '' && newUserName !== ''){
            const newMember = { name: newUserName, email: newUserEmail };
            emailsToAdd = [...emailsToAdd, newMember];
            setNewUserName('');
            setNewUserEmail('');
        }

        const dbToUpdate = {
            state: "active",
            emailsToAdd: emailsToAdd,
            emailsToUpdate: emailsToUpdate
        }
        const response = await putData(`/lists/${id}`, dbToUpdate, idToken);
        if (response.status === 200 || response.status === 201) {
            Toaster.show(`Base de dados ${name} updated com sucesso.`, 'success');
            setcontacts(JSON.parse(response.data.contacts));
            clearCache(`/lists/${id}`);
            setDataBases(databases);
            for (let i = 0; i < sessionStorage.length; i++) {
                const key = sessionStorage.key(i);
                if (key && key.includes('campaigns')) {
                    clearCache(key);
                }
            }
            setData([]);
            setSelectedMembers([]);
            setEmailsToUpdate([]);
        } else {
            Toaster.show('Não foi possível editar a base de dados. Tente novamente.', 'error');
            console.error('Update data base failed');
        }
        setLoading(false);
    }


    const handleAddMember = (username: string, email: string) => {
        if (selectedMembers.some((elem) => elem.name === username && elem.email === email)) {
            setSelectedMembers(selectedMembers.filter((elem) => elem.name !== username || elem.email !== email));
        } else {
            setSelectedMembers([...selectedMembers, { name: username, email: email }]);
        }
    };

    const toggleCancel = () => {
        setShowModal(false);
        setSelectedMembers([]);
    };


    const handleDownloadExample = () => {
        const exampleData = [
            { name: 'Pedro Pardal', email: 'pedro.pardal@example.com' },
            { name: 'Inácio Orácio', email: 'inacio.oracio@example.com' }
        ];
        const worksheet = XLSX.utils.json_to_sheet(exampleData);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Example');
        const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
        const blob = new Blob([excelBuffer], { type: 'application/octet-stream' });
        saveAs(blob, 'example.xlsx');
    };



    useEffect(() => {
        setFilteredcontacts(contacts);
    }, [contacts]);

    useEffect(() => {
        getDB()
    }, [getDB]);


    useEffect(() => {
        if (search === '') {
            setFilteredcontacts(contacts);
            return;
        }
        const filtered = contacts.filter((user) =>
            user.name.toLowerCase().includes(search.toLowerCase())
            || user.email.toLowerCase().includes(search.toLowerCase())
        );
        setFilteredcontacts(filtered);

    }, [search, contacts]);


    return (
        <Page >
            <div className='h-screen'>
                <div className='flex flex-col gap-8'>
                    <button onClick={() => navigate(-1)} className='flex gap-4 items-center cursor-pointer'>
                        <IoMdArrowRoundBack size={32} title='Go Back' />
                        <p>Go Back</p>
                    </button>
                </div>
                <div className='flex flex-col gap-6'>
                    <div className='flex justify-between gap-10 items-center'>
                        <div className='flex gap-4 items-center pt-10'>
                            <h2 className='text-xl text-zinc-500'>{lang === "pt" ? "Base de Dados" : "Data Base"}</h2>
                            <div style={{ backgroundColor: `#${color}` }} className="w-8 h-8 text-white rounded-full flex items-center justify-center">{number}</div>
                        </div>
                        <button onClick={updateDb} className='w-32 p-2 bg-dashBlue text-white rounded-full text-lg'>
                            Save
                        </button>
                    </div>
                    <div className='flex gap-10 justify-between'>
                        <div className='flex flex-col gap-2'>
                            <p className='text-lg text-zinc-400'>{lang === "pt" ? "Nome" : "Name"}</p>
                            <p className='text-lg'>{name}</p>
                        </div>
                        <div className="flex flex-col gap-2">
                            <p className='text-lg text-zinc-400 text-end'>{lang === "pt" ? "Estado" : "state"}</p>
                            <div className='flex gap-4'>
                                <p>{lang === "pt" ? "Ativa" : "Active"}</p>
                                <Toggle
                                    disabled
                                    defaultChecked={true}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="py-6">
                    <div className="flex items-center gap-2 justify-between pb-6">
                        <div className='flex gap-2 w-full items-center'>
                            <div className='group relative'>
                                <IoHelpCircleSharp className='text-zinc-500' size={40} />
                                <span className='absolute top-12 scale-0 group-hover:scale-100 transition-all duration-200 ease-in-out bg-zinc-100 w-60 aspect-square rounded-2xl p-2'>
                                    <p className='text-lg text-black'>Ajuda</p>
                                    <p className='text-sm text-zinc-500'>
                                        Poderá pesquisar por nome ou número de equipa.
                                        Equipas inativas são marcadas a vermelho e ativas a verde.
                                        As equipas ativas são as que têm menos membros do que o máximo permitido.
                                        Poderá adicionar uma nova equipa clicando no botão no canto superior direito e posteriormente adicionar novos membros.
                                    </p>
                                </span>
                            </div>
                            <div className="w-1/2">
                                <div className=" bg-gray-200 rounded-full shadow flex px-2 py-1 w-fit">
                                    <input type="text" name="search" className={`${showSearch ? "w-60 px-4" : "w-0"} transition-all duration-300 ease-in-out dark:text-gray-800 outline-none bg-transparent`} onChange={handleSearchChange} value={search} />
                                    <button type={showSearch ? "submit" : "button"}>
                                        <div>
                                            <IoIosSearch onClick={() => setShowSearch(!showSearch)} className='text-zinc-500' size={36} title="Pesquisar" />
                                        </div>
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className='flex flex-col gap-4 py-4'>
                            <input
                                type="file"
                                accept=".xlsx, .xls, .csv"
                                onChange={handleFileChange}
                                style={{ display: 'none' }}
                                id="fileInput"
                                ref={fileInputRef}
                            />
                            <button onClick={() => setShowModal(true)} className='w-52 p-3 bg-dashBlue text-white rounded-full text-lg'>
                                <div className='flex gap-4 justify-center'>
                                    <p>Import</p>
                                    <LuImport size={28} title='Import' />
                                </div>
                            </button>

                        </div>
                    </div>
                        <div className='relative'>

                            <div className='scroll overflow-x-auto max-h-96 h-96 pr-2'>
                                <div className=' w-full'>
                                    <table className=' w-full'>
                                        <thead >
                                            <tr className='text-sm text-left text-zinc-500 '>
                                                <th className="px-6 py-3 ">
                                                    {lang === "pt" ? "Nome" : "Name"}
                                                </th>
                                                <th className="px-6 py-3  min-w-[8rem]">
                                                    E-Mail
                                                </th>
                                                <th className="px-6 py-3 ">
                                                    {lang === "pt" ? "Selecionar" : "Select"}
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody className='overflow-y-auto '>
                                            {filteredcontacts.map((elem, index) => (
                                                <tr key={index} className=' text-zinc-500  border-t-2 border-zinc-200'>
                                                    <td className="px-6 py-10">
                                                        <div className="text-sm font-bold text-black rounded-2xl ">{elem.name}</div>
                                                    </td>
                                                    <td className="px-6 py-10">
                                                        <div className="text-sm font-bold text-black rounded-2xl ">{elem.email}</div>
                                                    </td>

                                                    <td className="px-6 py-10">
                                                        <button
                                                            onClick={() => handleSelect(index, elem.email)}
                                                            className={`w-8 ${(checked.includes(index) || elem.state === "active") && "bg-dashBlue"} cursor-pointer aspect-square border border-solid border-black rounded-lg flex items-center justify-center text-white select-none`}>
                                                            <span>&#10003;</span>
                                                        </button>
                                                    </td>
                                                </tr>
                                            ))}
                                            <tr className='sticky bg-white w-full bottom-0 text-zinc-500 border-t-2 border-zinc-200'>
                                                <td className="px-4 cinco:px-6 py-10">
                                                <input
                                                        value={newUserName}
                                                        onChange={(e) => setNewUserName(e.target.value)}
                                                        placeholder='| UserName'
                                                        className="text-sm font-bold text-black px-4 py-2"
                                                    />
                                                </td>
                                                <td className="px-4 cinco:px-6 py-10">
                                                    <input
                                                        value={newUserEmail}
                                                        onChange={(e) => setNewUserEmail(e.target.value)}
                                                        placeholder='| Email'
                                                        className="text-sm font-bold text-black px-4 py-2"
                                                    />
                                                </td>
                                                <td className="px-4 cinco:px-6 py-10 text-sm font-medium">
                                                    <button onClick={updateDb} className="text-sm font-bold text-indigo-600 hover:text-indigo-900">
                                                        Add
                                                    </button>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                </div>
            </div>
            <Modal isOpen={showModal} handler={updateDb}>
                <div className='p-8'>
                    <div className='flex justify-between'>
                        <div>
                            <p className='text-xl'>{lang === "pt" ? "Adicionar Membros" : "Add Members"}</p>
                        </div>
                        <IoIosClose onClick={() => setShowModal(false)} className='cursor-pointer' size={40} title={lang === "pt" ? "Fechar" : "Close"} />
                    </div>
                    <div className='flex flex-col gap-4 py-4'>
                        <input
                            type="file"
                            accept=".xlsx, .xls"
                            onChange={handleFileChange}
                            style={{ display: 'none' }}
                            id="fileInput"
                            ref={fileInputRef}
                        />
                        <div className='flex gap-4 flex-wrap '>
                            <button onClick={handleButtonClick} className='w-52 p-3 bg-dashBlue hover:bg-dashBlueDark transition-all ease-in-out duration-500 text-white rounded-full text-lg'>
                                <div className='flex gap-4 justify-center'>
                                    <p>Import</p>
                                    <LuImport size={28} title='Import' />
                                </div>
                            </button>
                            <button onClick={handleDownloadExample} className='w-52 p-3 bg-green-400 hover:bg-green-500 transition-all ease-in-out duration-500 text-white rounded-full text-lg'>
                                <div className='flex gap-4 justify-center'>
                                    <p>Download Example</p>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className='py-6'>
                        <div className='p-4 bg-zinc-100 text-zinc-400 rounded-xl flex items-center gap-2'>
                            <IoHelpCircleSharp size={32} />
                            <div className='flex flex-col gap-1'>
                                <p className='text-sm'>{lang === "pt" ?
                                    "Importe um ficheiro Excel e selecione da lista os elementos que deseja adicionar à base de dados. Tem também um ficheiro de exemplo que pode transferir" :
                                    "Import a Excel file and select from the list the elements you want to add to the database. You also have an example file that you can download."}
                                </p>
                                <p className='text-sm'>{lang === "pt" ?
                                    "Note que o ficheiro deve possuir apenas duas colunas, uma com o nome e outra com o email." :
                                    "Note that the file must have only two columns, one with the name and another with the email."}
                                </p>
                            </div>
                        </div>
                    </div>
                    {data.length > 0 && <div className='scroll overflow-x-auto max-h-96 pr-2'>

                        <table className="min-w-full">
                            <thead className="">
                                <tr className='text-sm text-left text-zinc-500 '>
                                    <th className="px-6 py-3 ">
                                        {lang === "pt" ? "Nome" : "Name"}
                                    </th>
                                    <th className="px-6 py-3 ">
                                        Email
                                    </th>
                                    <th className="px-6 py-3 ">
                                        {lang === "pt" ? "Selecionar" : "Select"}
                                    </th>
                                </tr>
                            </thead>
                            <tbody className="">
                                {data.map((elem, index) => (
                                    <tr key={index} className=' text-zinc-500  border-t-2 border-zinc-200'>
                                        <td className="px-6 py-10">
                                            <div className="text-sm font-bold text-black rounded-2xl ">{elem.name}</div>
                                        </td>
                                        <td className="px-6 py-10  ">
                                            <div className="text-sm rounded-2xl ">{elem.email}</div>
                                        </td>
                                        <td className="px-6 py-10 text-sm font-medium flex justify-center">
                                            <div
                                                className={`cursor-pointer border flex items-center justify-center border-black rounded-lg w-6 aspect-square ${selectedMembers.some(member => member.email === elem.email) ? 'bg-blue-600 ' : ''}`}
                                                onClick={() => handleAddMember(elem.name, elem.email)}
                                            >
                                                <FaCheck className='text-white' />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>}
                    <div className='flex gap-8 justify-end pt-8'>
                        <button onClick={toggleCancel} className="bg-zinc-200 text-zinc-500 py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Cancelar" : "Cancel"}</span>
                        </button>
                        <button onClick={updateDb} className="bg-dashBlue text-white py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Adicionar" : "Add"}</span>
                        </button>
                    </div>
                </div>
            </Modal>
            <Loading isOpen={loading} />
        </Page>

    );
}

