import React, { useEffect } from 'react';

import { BrowserRouter, Route, Routes, useNavigate } from 'react-router-dom';

// Components
import Navbar from './components/Navbar';

// Pages
import Login from './pages/Login';
import Campains from './pages/Campains/Campaigns';
import NotFoundPage from './pages/NotFound';
import { UserProvider, useUser } from './contexts/userContext';
import DataBases from './pages/DataBase/DataBases';
import DataBase from './pages/DataBase/DataBase';
import BuilderInSite from './pages/Builder/InSiteBuilder';
import CampaignBuilder from './pages/Builder/CampaignBuilder';
import Builder from './pages/Builder/Builder';
import CreateEditCampain from './pages/Campains/Campaign';
import HelpCenter from './pages/HelpCenter';
import EditHTML from './pages/Builder/InSiteEditHTML';
import Stats from './pages/Stats';
import Senders from './pages/Senders';
import { Toaster } from 'react-hot-toast';
import { CampaignProvider } from './contexts/campaignsContext';
import { DataBaseProvider } from './contexts/databasesContext';


function App() {
	const Layout = ({ children }: { children: any }) => {
		const { user } = useUser();
		const navigate = useNavigate();
		useEffect(() => {
			if (!user) {
				navigate('/');
			}
		}, [user, navigate]);
		return (
			<>
				{user && <div className='w-full'>
					<Navbar />
					<div className='text-[var(--black)] pt-[5rem] oito:pt-0 oito:pl-[17rem]'>
						{children}
					</div>
				</div>}
			</>
		);
	};

	return (
		<UserProvider>
			<CampaignProvider>
				<DataBaseProvider>
					<Toaster />
					<BrowserRouter>
						<Routes>
							<Route index element={<Login />} />
							<Route path="/campaigns" element={<Layout><Campains /></Layout>} />
							<Route path="/campaigns/:campainName" element={<Layout><CreateEditCampain /></Layout>} />
							<Route path="/senders" element={<Layout><Senders /></Layout>} />
							<Route path="/dataBases" element={<Layout><DataBases /></Layout>} />
							<Route path="/dataBases/:dataBaseName" element={<Layout><DataBase /></Layout>} />
							<Route path="/builder/inSite" element={<Layout><BuilderInSite /></Layout>} />
							<Route path="/builder/inSite/:htmlTemplate" element={<Layout><EditHTML /></Layout>} />
							<Route path="/builder/campaign" element={<Layout><CampaignBuilder /></Layout>} />
							<Route path="/builder" element={<Layout><Builder /></Layout>} />
							{/* <Route path="/stats" element={<Layout><Stats /></Layout>} /> */}
							<Route path="/help" element={<Layout><HelpCenter /></Layout>} />
							<Route path="*" element={<NotFoundPage />} />
						</Routes>
					</BrowserRouter>
				</DataBaseProvider>
			</CampaignProvider>
		</UserProvider>
	);
}

export default App;
