import React from 'react';
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import "./toggle.styles.scss";

interface ToggleProps {
    index: number;
    status: boolean;
    values: any[];
    setValue: (value: any) => void;
}


export default function Input({ index, status, setValue, values }: ToggleProps) {
    const handleStatusChange = (index: number) => {
        // Create a new array with the updated employee's status
        const updatedEmployees = values.map((val, i) => {
            if (i === index) {
                return { ...val, status: val.status === "active" ? "inactive" : "active" };
            }
            return val;
        });

        // Update the state
        setValue(updatedEmployees);
    }

    return (
        <Toggle
            defaultChecked={status}
            onChange={() => handleStatusChange(index)} />
    );
}
