import { Inputs } from "../../interfaces/Inputs";
import Toggle from 'react-toggle';
import "react-toggle/style.css"
import "../../components/Toggle/toggle.styles.scss";
import { IoHelpCircleSharp } from "react-icons/io5";
import { IoClose } from "react-icons/io5";
import { IoAddCircle } from "react-icons/io5";
import { useState } from "react";
import Modal from "../../components/Modal";
import toast from 'react-hot-toast';
import { IoIosClose } from "react-icons/io";

export default function InputsComponent({ inputs, setInputs }: { inputs: Inputs, setInputs: React.Dispatch<React.SetStateAction<Inputs >> }) {
    const lang = navigator.language || 'en';
    const [showModal, setShowModal] = useState(false);
    const [newField, setNewField] = useState('');
    const [fieldToChange, setFieldToChange] = useState('');
    function handleRemovePersonalized(indexToRemove: number) {
        setInputs(prevInputs => ({
            ...prevInputs,
            personalized_list: prevInputs.personalized_list.filter((_, index) => index !== indexToRemove)
        }));
    }
    function handleRemoveCheckbox(indexToRemove: number) {
        setInputs(prevInputs => ({
            ...prevInputs,
            checkbox_list: prevInputs.checkbox_list.filter((_, index) => index !== indexToRemove)
        }));
    }


    const toggleCancel = () => {
        setNewField('');
        setShowModal(false);
    }

    const handleAddField = () => {
        if (newField) {
            if (fieldToChange === "input") {
                const newInputs = { ...inputs }
                newInputs.personalized_list.push(newField);
                setInputs(newInputs)
                setNewField('');
                setShowModal(false);
            } else if (fieldToChange === "checkbox") {
                const newInputs = { ...inputs }
                newInputs.checkbox_list.push(newField);
                setInputs(newInputs)
                setNewField('');
                setShowModal(false);
            }
        } else {
            toast.error(lang === "pt" ? "Insira um nome para o campo" : "Insert a name for the field")
        }
    }

    return (
        <div className="py-10 flex flex-col gap-10">
            <div>
                <p className="text-xl font-bold">Text</p>
                <div className="grid grid-cols-2 gap-8 py-4">
                    <div className="flex gap-6 items-center justify-between">
                        <p className="text-xl">Name</p>
                        <Toggle
                            checked={inputs.name}
                            onChange={() => setInputs({ ...inputs, name: !inputs.name })}
                        />
                    </div>
                    <div className="flex gap-6 items-center justify-between">
                        <p className="text-xl font-semibold">Email</p>
                        <Toggle
                            checked={inputs.email}
                            onChange={() => setInputs({ ...inputs, email: !inputs.email })}
                        />
                    </div>
                    <div className="flex gap-6 items-center justify-between">
                        <p className="text-xl font-semibold">Last Name</p>
                        <Toggle
                            checked={inputs.last_name}
                            onChange={() => setInputs({ ...inputs, last_name: !inputs.last_name })}
                        />
                    </div>
                    <div className="flex gap-6 items-center justify-between">
                        <p className="text-xl font-semibold">Phone</p>
                        <Toggle
                            checked={inputs.phone}
                            onChange={() => setInputs({ ...inputs, phone: !inputs.phone })}
                        />
                    </div>
                </div>

            </div>
            <div>
                <div className="flex justify-between gap-10 items-center">
                    <p className="text-xl font-bold">Personalized</p>
                    <div>
                        <div className='group relative'>
                            <IoHelpCircleSharp className='text-zinc-500' size={40} />
                            <span className='z-50 absolute top-12 scale-0 group-hover:scale-100 transition-all duration-200 ease-in-out bg-zinc-100 w-60 rounded-2xl p-2'>
                                <p className='text-lg text-black'>Ajuda</p>
                                <p className='text-sm text-zinc-500'>
                                    Poderá adicionar inputs personalizados ao seu template
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="py-4 flex gap-10">
                    {inputs.personalized_list && inputs.personalized_list.length > 0 &&
                        <div className="flex flex-wrap gap-4">
                            {inputs.personalized_list.map((input, index) => (
                                <div key={index} className="flex gap-4 border border-solid border-zinc-400 px-4 py-2 rounded-full">
                                    <p className="text-lg">{input}</p>
                                    <IoClose className="cursor-pointer" size={24} title="Remove" onClick={() => handleRemovePersonalized(index)} />
                                </div>
                            ))}
                        </div>
                    }
                    <div className="flex justify-center gap-8 h-12 items-center">
                        <IoAddCircle className="cursor-pointer text-dashBlue" size={32} title="Add" onClick={() => { setShowModal(true); setFieldToChange("input") }} />
                        <Toggle
                            checked={inputs.personalized}
                            onChange={() => setInputs({ ...inputs, personalized: !inputs.personalized })}
                        />
                    </div>
                </div>
            </div>
            <div>
                <div className="flex justify-between gap-10 items-center">
                    <p className="text-xl font-bold">Checkbox</p>
                    <div>
                        <div className='group relative'>
                            <IoHelpCircleSharp className='text-zinc-500' size={40} />
                            <span className='z-50 absolute top-12 scale-0 group-hover:scale-100 transition-all duration-200 ease-in-out bg-zinc-100 w-60 rounded-2xl p-2'>
                                <p className='text-lg text-black'>Ajuda</p>
                                <p className='text-sm text-zinc-500'>
                                    Poderá adicionar checkboxes personalizadas ao seu template
                                </p>
                            </span>
                        </div>
                    </div>
                </div>
                <div className="py-4 flex gap-10">
                    {inputs.checkbox_list && inputs.checkbox_list.length > 0 &&
                        <div className="flex flex-wrap gap-4">
                            {inputs.checkbox_list.map((input, index) => (
                                <div key={index} className="flex gap-4 border border-solid border-zinc-400 px-4 py-2 rounded-full">
                                    <p className="text-lg">{input}</p>
                                    <IoClose className="cursor-pointer" size={24} title="Remove" onClick={() => handleRemoveCheckbox(index)} />
                                </div>
                            ))}
                        </div>
                    }
                    <div className="flex justify-center gap-8 h-12 items-center">
                        <IoAddCircle className="cursor-pointer text-dashBlue" size={32} title="Add" onClick={() => { setShowModal(true); setFieldToChange("checkbox") }} />
                        <Toggle
                            checked={inputs.checkbox}
                            onChange={() => setInputs({ ...inputs, checkbox: !inputs.checkbox })}
                        />
                    </div>
                </div>
            </div>
            <Modal isOpen={showModal} handler={handleAddField}>
                <div className='p-8'>
                    <div className='flex justify-between'>
                        <div>
                            <p className='text-xl'>{lang === "pt" ? "Adicionar Campo" : "Add Field"}</p>
                        </div>
                        <IoIosClose onClick={() => setShowModal(false)} className='cursor-pointer' size={40} title={lang === "pt" ? "Fechar" : "Close"} />
                    </div>
                    <div className='py-6'>
                        <div className='p-4 bg-zinc-100 text-zinc-400 rounded-xl flex items-center gap-2'>
                            <IoHelpCircleSharp size={24} />
                            <p className='text-sm'>{lang === "pt" ?
                                "Insira o nome do campo que deseja atribuir." :
                                "Insert the name of the field you want to add."}
                            </p>
                        </div>
                        <div className='py-8 flex flex-col gap-2'>
                            <label htmlFor="teamName" className='text-sm'>{lang === "pt" ? "Nome do Campo" : "Field Name"}</label>
                            <input
                                className="w-full outline-none px-4 py-2 text-sm border border-gray-300 border-solid rounded-full"
                                type="text"
                                placeholder='| Field'
                                onChange={(e) => setNewField(e.target.value)}
                                value={newField}
                            />
                        </div>
                    </div>
                    <div className='flex gap-8 justify-end'>
                        <button onClick={toggleCancel} className="bg-zinc-200 text-zinc-500 py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Cancelar" : "Cancel"}</span>
                        </button>
                        <button onClick={handleAddField} className="bg-dashBlue text-white py-2 px-4 rounded-xl flex items-center justify-center gap-2">
                            <span>{lang === "pt" ? "Adicionar" : "Add"}</span>
                        </button>

                    </div>
                </div>
            </Modal>
        </div>
    )
}