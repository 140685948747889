import React from 'react';
import "../../components/customScroll.styles.scss";
import { useNavigate } from 'react-router-dom';
import { Campaign } from '../../interfaces/Campaign';
import stateBgColor from '../../components/StatusHandler';

export default function CampaignDashView({ filteredcampaigns, inactiveDraftsCampaigns }: { filteredcampaigns: Campaign[], inactiveDraftsCampaigns: Campaign[] }) {
    const lang = navigator.language || 'en';
    const navigate = useNavigate();

    const CampaignCard = ({ campaign }: { campaign: Campaign }) => {

        const stateColor = stateBgColor(campaign.state);

        return (
            <div onClick={() => navigate(campaign.name, { state: { id: campaign.id, name: campaign.name, color: campaign.color, number: campaign.number, state: campaign.state } })} className={`border-2 cursor-pointer border-zinc-300 rounded-2xl p-4 flex flex-col gap-4`}>
                <div className="flex items-center justify-between">
                    <span className="text-zinc-500 text-xl">Campanha</span>
                    <div style={{ backgroundColor: `#${campaign.color}` }} className="w-8 h-8 text-white rounded-full flex items-center justify-center">{campaign.number}</div>
                </div>
                <span className="text-lg break-words">{campaign.name}</span>
                <div className="flex items-center gap-2">
                    <div className={`w-3 h-3 ${stateColor.color} rounded-full`}></div>
                    <span className="text-black">{stateColor.text}</span>
                </div>
            </div>
        )
    }


    return (
        <div className='scroll overflow-auto max-h-[45rem] w-full pr-2'>

            {filteredcampaigns.length > 0 ?
                <div className='pb-8'>
                    <p className='text-xl text-zinc-500 p-8 pl-0'>{lang === "pt" ? "Ativas" : "Active"}</p>
                    <div className="grid cinco:grid-cols-2 miledois:grid-cols-3 gap-10">
                        {filteredcampaigns.map((campaign, index) => (
                            <CampaignCard key={index} campaign={campaign} />
                        ))}
                    </div>
                </div>
                :
                <div className="flex justify-center items-center h-96">
                    <p className='text-xl text-zinc-500'>{lang === "pt" ? "Sem Campanhas ativas, por favor crie uma nova e ative-a" : "No active campaigns, please create a new one and activate it"}</p>
                </div>
            }
            <hr></hr>
            {inactiveDraftsCampaigns.length > 0 &&
                <div>
                    <p className='text-xl text-zinc-500 p-8 pl-0'>{lang === "pt" ?  "Inativas" : "Inactive"}</p>
                    <div className="grid cinco:grid-cols-2 miledois:grid-cols-3 gap-10">
                        {inactiveDraftsCampaigns.map((campaign, index) => (
                            <CampaignCard key={index} campaign={campaign} />
                        ))}
                    </div>
                </div>
            }

        </div>
    );
}

